// import React, { useState, useRef } from 'react';
// import Logo from '../images/logo.webp';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faTimes } from '@fortawesome/free-solid-svg-icons';
// import { useNavigate } from 'react-router-dom';
// import { logoutService } from '../services/LogoutService';
// import LogoutModel from "./LogoutModel";
// import { sendOtp } from '../services/Api';
// import { handleError } from '../services/errorHandler';

// function SignDocument() {
//   const navigate = useNavigate();

//   const [showLogoutModal, setShowLogoutModal] = useState(false);
//   const [showOtpModal, setShowOtpModal] = useState(false);  // State to control OTP modal visibility
//   const [isChecked, setIsChecked] = useState(false);
//   const [otp, setOtp] = useState(Array(6).fill(''));
//   const inputRefs = useRef(new Array(6).fill(null));
//   const handleLogoutClick = () => setShowLogoutModal(true);
//   const restaurantId = localStorage.getItem("restaurant_Id");

//   const confirmLogout = () => {
//     setShowLogoutModal(false);
//     logoutService('Session');
//   };

//   const handleCheckboxChange = () => {
//     setIsChecked(!isChecked);
//   };

//   const handleSendOtp = async (e) => {
//     e.preventDefault();
//     try {
//       await sendOtp(restaurantId);
//       setShowOtpModal(true);
//       // console.log('OTP has been sent to', phone);
//     } catch (error) {
//       handleError(error);
//     }
//   };

//   // Handle OTP input change
//   const handleOtpChange = (index, value) => {
//     if (value.length === 1 && /^\d*$/.test(value)) {
//       const newOtp = [...otp];
//       newOtp[index] = value;
//       setOtp(newOtp);

//       if (index < 5) {
//         inputRefs.current[index + 1]?.focus();  // Focus on the next input field
//       }
//     } else if (value === '') {
//       const newOtp = [...otp];
//       newOtp[index] = '';  // Clear the current input
//       setOtp(newOtp);
//       inputRefs.current[index]?.focus();  // Stay on the same field
//     }
//   };

//   // Handle backspace key press in OTP fields
//   const handleKeyDown = (index, event) => {
//     if (event.key === 'Backspace') {
//       const newOtp = [...otp];
//       newOtp[index] = '';  // Clear current digit only

//       // Update the OTP state
//       setOtp(newOtp);

//       // Move focus to the previous input, but don't clear it
//       if (index > 0 && otp[index] === '') {
//         inputRefs.current[index - 1]?.focus();
//       }
//     }
//   };

//   const handleSignClick = () => {
//     setShowOtpModal(false);
//     navigate('/complete-sign'); // Replace '/complete-sign' with your actual route
//   };

//   return (
//     <div className="bg-gray-100 min-h-screen flex flex-col">
//       {" "}
//       {/* Ensure full height and background */}
//       {/* <header className="w-full bg-white shadow-md flex items-center justify-between p-3">
//         <div className="flex items-center">
//           <img src={Logo} alt="Logo" className="h-12" />
//         </div>
//         <div className="flex items-center">
//           <button
//             className="bg-green-600 text-white py-2 px-10 rounded cursor-pointer"
//             onClick={handleLogoutClick}
//           >
//             Logout
//           </button>
//         </div>
//       </header> */}
//       <main className="flex-grow flex flex-col items-center mt-20 w-full">
//         <div className=" shadow-lg rounded-lg overflow-hidden flex flex-col items-center">
//           {/* Display the PDF with full width */}
//           <div className="w-full">
//             <iframe
//               src={
//                 "https://s3.ap-south-1.amazonaws.com/merchant-assets.thegenie.in/restaurant/MerchantAgreements/dummyRestaurant/The+Genie+Merchant+Onboarding+Documents.pdf"
//               }
//               title="Document"
//               className="w-full h-screen border-0"
//             ></iframe>
//           </div>

//           <div className="w-full p-6 flex flex-col items-center bg-gray-100">
//             {" "}
//             {/* Add background color */}
//             <div className="checkbox-container flex items-center mt-4">
//               <input
//                 type="checkbox"
//                 id="terms"
//                 name="terms"
//                 className="mr-2"
//                 checked={isChecked}
//                 onChange={handleCheckboxChange}
//               />
//               <label htmlFor="terms" className="text-sm">
//                 I have read and understood the terms stipulated in the
//                 merchant agreement. I consent to the terms outlined.
//               </label>
//             </div>
//             <button
//               onClick={handleSendOtp}
//               disabled={!isChecked} // Disable button if checkbox is not checked
//               className={`text-white text-center rounded h-10 w-44 mt-5 ${isChecked
//                 ? "bg-green-600 cursor-pointer"
//                 : "bg-gray-400 cursor-not-allowed"
//                 }`}
//             >
//               Proceed to Sign
//             </button>
//           </div>
//         </div>
//       </main>
//       {/* OTP Modal code here */}
//       {showOtpModal && (
//         <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
//           <div className="bg-white rounded-lg shadow-lg p-5 w-full max-w-sm">
//             <div className="flex justify-between items-center mb-4">
//               <h2 className="text-xl font-semibold">
//                 Enter verification code
//               </h2>
//               <button
//                 onClick={() => setShowOtpModal(false)}
//                 className="text-gray-400 hover:text-gray-600"
//               >
//                 <FontAwesomeIcon icon={faTimes} className="text-xl" />
//               </button>
//             </div>
//             <p className="text-center text-gray-600 mb-4">
//               6 digit OTP has been sent to your registered mobile number
//             </p>
//             <div className="flex justify-center space-x-2 mb-4">
//               {otp.map((digit, index) => (
//                 <input
//                   key={index}
//                   type="text"
//                   maxLength="1"
//                   className="w-10 h-10 border rounded text-center shadow-md focus:outline-none focus:ring-2 focus:ring-red-500"
//                   value={digit}
//                   onChange={(e) => handleOtpChange(index, e.target.value)}
//                   onKeyDown={(e) => handleKeyDown(index, e)}
//                   ref={(el) => inputRefs.current[index] = el}
//                 />
//               ))}
//             </div>
//             <div className="flex justify-center mt-4">
//               <button
//                 onClick={handleSignClick}
//                 className={`text-white text-center rounded h-10 w-36 ${otp.join('').length === 6 ? 'bg-green-600 cursor-pointer' : 'bg-gray-400 cursor-not-allowed'}`}
//                 disabled={otp.join('').length !== 6}
//               >
//                 Sign
//               </button>
//             </div>
//           </div>
//         </div>
//       )}
//       {showLogoutModal && (
//         <LogoutModel
//           onClickLogout={confirmLogout}
//           onClickCancel={() => setShowLogoutModal(false)}
//         />
//       )}
//     </div>
//   );
// }

// export default SignDocument;





// import React, { useReducer, useRef } from 'react';
// import Logo from '../images/logo.webp';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faTimes } from '@fortawesome/free-solid-svg-icons';
// import { useNavigate } from 'react-router-dom';
// import { logoutService } from '../services/LogoutService';
// import LogoutModel from "./LogoutModel";
// import { AgreementsendOtp, signMerchantAgreementDocument } from '../services/Api';
// import { handleError } from '../services/errorHandler';

// const initialState = {
//   showLogoutModal: false,
//   showOtpModal: false,
//   isChecked: false,
//   otp: Array(6).fill(''),
// };

// function reducer(state, action) {
//   switch (action.type) {
//     case 'SET_SHOW_LOGOUT_MODAL':
//       return { ...state, showLogoutModal: action.payload };
//     case 'SET_SHOW_OTP_MODAL':
//       return { ...state, showOtpModal: action.payload };
//     case 'SET_IS_CHECKED':
//       return { ...state, isChecked: !state.isChecked };
//     case 'SET_OTP':
//       return { ...state, otp: action.payload };
//     default:
//       return state;
//   }
// }

// function SignDocument() {
//   const [state, dispatch] = useReducer(reducer, initialState);
//   const navigate = useNavigate();
//   const inputRefs = useRef(new Array(6).fill(null));

//   const restaurantId = localStorage.getItem("restaurant_Id");

//   const confirmLogout = () => {
//     dispatch({ type: 'SET_SHOW_LOGOUT_MODAL', payload: false });
//     logoutService('Session');
//   };

//   const handleSendOtp = async (e) => {
//     e.preventDefault();
//     try {
//       await AgreementsendOtp(restaurantId);
//       dispatch({ type: 'SET_SHOW_OTP_MODAL', payload: true });
//     } catch (error) {
//       handleError(error);
//     }
//   };

//   // Handle OTP input change
//   const handleOtpChange = (index, value) => {
//     if (value.length === 1 && /^\d*$/.test(value)) {
//       const newOtp = [...state.otp];
//       newOtp[index] = value;
//       dispatch({ type: 'SET_OTP', payload: newOtp });

//       if (index < 5) {
//         inputRefs.current[index + 1]?.focus();
//       }
//     } else if (value === '') {
//       const newOtp = [...state.otp];
//       newOtp[index] = '';
//       dispatch({ type: 'SET_OTP', payload: newOtp });
//       inputRefs.current[index]?.focus();
//     }
//   };

//   const handleKeyDown = (index, event) => {
//     if (event.key === 'Backspace') {
//       const newOtp = [...state.otp];
//       newOtp[index] = '';
//       dispatch({ type: 'SET_OTP', payload: newOtp });

//       if (index > 0 && state.otp[index] === '') {
//         inputRefs.current[index - 1]?.focus();
//       }
//     }
//   };

//   const handleSignClick = async () => {
//     dispatch({ type: 'SET_SHOW_OTP_MODAL', payload: false });

//     const metadataId = localStorage.getItem("AgreementMetaDataId");
//     const ownerEmail = localStorage.getItem("ownerEmail");
//     const ownerMobile = localStorage.getItem("ownerMobile");
//     const restaurantId = localStorage.getItem("restaurant_Id");

//     if (!metadataId || !ownerEmail || !ownerMobile || !restaurantId) {
//       console.error("Missing required information to sign document.");
//       return;
//     }

//     try {
//       const response = await signMerchantAgreementDocument(metadataId, ownerEmail, ownerMobile, restaurantId);
//       navigate('/complete-sign');
//     } catch (error) {
//       throw error;
//     }
//   };

//   return (
//     <div className="bg-gray-100 min-h-screen flex flex-col">
//       <header className="w-full bg-white shadow-md flex items-center justify-between p-3">
//         <div className="flex items-center">
//           <img src={Logo} alt="Logo" className="h-12" />
//         </div>
//         {/* <div className="flex items-center">
//           <button
//             className="bg-green-600 text-white py-2 px-10 rounded cursor-pointer"
//             onClick={() => dispatch({ type: 'SET_SHOW_LOGOUT_MODAL', payload: true })}
//           >
//             Logout
//           </button>
//         </div> */}
//       </header>
//       <main className="flex-grow flex flex-col items-center mt-5 w-full">
//         <div className="shadow-lg rounded-lg overflow-hidden flex flex-col items-center">
//           <div className="w-full">
//             <iframe
//               src="https://s3.ap-south-1.amazonaws.com/merchant-assets.thegenie.in/restaurant/MerchantAgreements/dummyRestaurant/The+Genie+Merchant+Onboarding+Documents.pdf"
//               title="Document"
//               className="w-full h-screen border-0"
//             ></iframe>
//           </div>

//           <div className="w-full p-6 flex flex-col items-center bg-gray-100">
//             <div className="checkbox-container flex items-center mt-4">
//               <input
//                 type="checkbox"
//                 id="terms"
//                 name="terms"
//                 className="mr-2"
//                 checked={state.isChecked}
//                 onChange={() => dispatch({ type: 'SET_IS_CHECKED' })}
//               />
//               <label htmlFor="terms" className="text-sm">
//                 I have read and understood the terms stipulated in the merchant agreement. I consent to the terms outlined.
//               </label>
//             </div>
//             <button
//               onClick={handleSendOtp}
//               disabled={!state.isChecked}
//               className={`text-white text-center rounded h-10 w-44 mt-5 ${state.isChecked ? "bg-green-600 cursor-pointer" : "bg-gray-400 cursor-not-allowed"}`}
//             >
//               Proceed to Sign
//             </button>
//           </div>
//         </div>
//       </main>

//       {/* OTP Modal */}
//       {state.showOtpModal && (
//         <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
//           <div className="bg-white rounded-lg shadow-lg p-5 w-full max-w-sm">
//             <div className="flex justify-between items-center mb-4">
//               <h2 className="text-xl font-semibold">Enter verification code</h2>
//               <button onClick={() => dispatch({ type: 'SET_SHOW_OTP_MODAL', payload: false })} className="text-gray-400 hover:text-gray-600">
//                 <FontAwesomeIcon icon={faTimes} className="text-xl" />
//               </button>
//             </div>
//             <p className="text-center text-gray-600 mb-4">
//               6 digit OTP has been sent to your registered mobile number
//             </p>
//             <div className="flex justify-center space-x-2 mb-4">
//               {state.otp.map((digit, index) => (
//                 <input
//                   key={index}
//                   type="text"
//                   maxLength="1"
//                   className="w-10 h-10 border rounded text-center shadow-md focus:outline-none focus:ring-2 focus:ring-red-500"
//                   value={digit}
//                   onChange={(e) => handleOtpChange(index, e.target.value)}
//                   onKeyDown={(e) => handleKeyDown(index, e)}
//                   ref={(el) => inputRefs.current[index] = el}
//                 />
//               ))}
//             </div>
//             <div className="flex justify-center mt-4">
//               <button
//                 onClick={handleSignClick}
//                 className={`text-white text-center rounded h-10 w-36 ${state.otp.join('').length === 6 ? 'bg-green-600 cursor-pointer' : 'bg-gray-400 cursor-not-allowed'}`}
//                 disabled={state.otp.join('').length !== 6}
//               >
//                 Sign
//               </button>
//             </div>
//           </div>
//         </div>
//       )}

//       {state.showLogoutModal && (
//         <LogoutModel
//           onClickLogout={confirmLogout}
//           onClickCancel={() => dispatch({ type: 'SET_SHOW_LOGOUT_MODAL', payload: false })}
//         />
//       )}
//     </div>
//   );
// }

// export default SignDocument;


// import React, { useState, useRef, useEffect } from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faTimes } from '@fortawesome/free-solid-svg-icons';
// import { preGenerateAgreementVerifyOtp, signMerchantAgreementDocument, agreementSendOtp, agreementReSendOtp } from '../services/Api';
// import { handleError } from '../services/errorHandler';
// import { useNavigate } from 'react-router-dom';

// const SignDocument = () => {

//   const navigate = useNavigate();
//   const inputRefs = useRef([]);

//   const [state, setState] = useState({
//     showOtpModal: false,
//     isOtpValid: false,
//     otp: Array(6).fill(''),
//     isOtpVerified: false,
//     restaurantId: localStorage.getItem("restaurant_Id"),
//     otpExpired: false,
//     agreementOtpExpiry: "",
//     agreementOtpResendWindow: "",
//     agreementUniqueAttemptId: "",
//     agreementOtpRetriesLeft: "",
//     maxResendAttempts: "",
//     isResendEnabled: false,
//     showOtpExpiredMessage: false,
//     errorMessage: "",
//     signatureMetadataId: '',
//     merchantEmailId: '',
//     ownerMobile: '',
//     isChecked: false,
//     requestId: "",
//   });

//   const handleOtpChange = (index, value) => {
//     if (value.length === 1 && /^\d*$/.test(value)) {
//       const newOtp = [...state.otp];
//       newOtp[index] = value;
//       setState(prevState => ({
//         ...prevState,
//         otp: newOtp,  // Updates the OTP in the state
//         isOtpValid: newOtp.join('').length === 6 // Check if OTP is fully entered
//       }));

//       if (index < 5) {
//         inputRefs.current[index + 1]?.focus();  // Focus on the next input field
//       }
//     } else if (value === '') {
//       const newOtp = [...state.otp];
//       newOtp[index] = '';  // Clear the current input
//       setState(prevState => ({
//         ...prevState,
//         otp: newOtp,  // Update the OTP in the state
//         isOtpValid: false // Set OTP valid to false if any field is cleared
//       }));
//       inputRefs.current[index]?.focus();  // Stay on the same field
//     }
//   };

//   // Handle backspace key press in OTP fields
//   const handleKeyDown = (index, event) => {
//     if (event.key === 'Backspace') {
//       const newOtp = [...state.otp];
//       newOtp[index] = '';  // Clear current digit only
//       setState(prevState => ({
//         ...prevState,
//         otp: newOtp,  // Update the OTP in the state
//       }));
//       // Move focus to the previous input, but don't clear it
//       if (index > 0 && state.otp[index] === '') {
//         inputRefs.current[index - 1]?.focus();
//       }
//     }
//   };

//   useEffect(() => {
//     let expiryTimer, resendTimer;
//     const currentTimestamp = Date.now();
//     console.log("Current Time Stamp", currentTimestamp);

//     console.log("OTP Expiry", state.agreementOtpExpiry);
//     console.log("OTP Resend", state.agreementOtpResendWindow);

//     const timeUntilExpiry = state.agreementOtpExpiry - currentTimestamp;
//     console.log("Time Until Expiry", timeUntilExpiry);

//     const timeUntilResend = state.agreementOtpResendWindow - currentTimestamp;
//     console.log("Time Until Resend", timeUntilResend);

//     console.log("Maximum Resend Attempts",state.maxResendAttempts);


//     // Set OTP expiry timer
//     if (timeUntilExpiry > 0) {
//       expiryTimer = setTimeout(() => {
//         setState(prevState => ({
//           ...prevState,
//           otpExpired: true
//         }))
//         if (state.maxResendAttempts > 0) {
//           setState(prevState => ({
//             ...prevState,
//             showOtpExpiredMessage: true
//           }))
//         }
//       }, timeUntilExpiry);
//     } else {
//       setState(prevState => ({
//         ...prevState,
//         otpExpired: true
//       }))
//       if (state.maxResendAttempts > 0) {
//         setState(prevState => ({
//           ...prevState,
//           showOtpExpiredMessage: true
//         }))
//       }
//     }
//     // Hide the expired message after 5 seconds
//     if (state.otpExpired && state.showOtpExpiredMessage) {
//       const hideMessageTimer = setTimeout(() => {
//         setState(prevState => ({
//           ...prevState,
//           showOtpExpiredMessage: false
//         }))
//       }, 5000);
//       return () => clearTimeout(hideMessageTimer); // Clear timeout on component unmount
//     }

//     // Set resend button enable timer
//     if (timeUntilResend > 0) {
//       resendTimer = setTimeout(() =>
//         setState(prevState => ({
//           ...prevState,
//           isResendEnabled: true
//         })), timeUntilResend);
//     } else {
//       setState(prevState => ({
//         ...prevState,
//         isResendEnabled: false
//       }))
//     }

//     return () => {
//       clearTimeout(expiryTimer);
//       clearTimeout(resendTimer);
//     };
//   }, [state.agreementOtpExpiry, state.agreementOtpResendWindow, state.otpExpired, state.maxResendAttempts, state.showOtpExpiredMessage]);

//   // Send OTP (Initial)
//   const handleSendOtp = async () => {
//     try {
//       const response = await agreementSendOtp(state.restaurantId);
//       const { otp, otpExpiry, otpResendWindow, uniqueAttemptId, totalAttempts } = response.otp;

//       // Store OTP details in localStorage
//       localStorage.setItem("agreementOtp", otp);
//       localStorage.setItem("agreementOtpExpiry", Date.now() + otpExpiry * 1000);
//       localStorage.setItem("agreementOtpResendWindow", Date.now() + otpResendWindow * 1000);
//       localStorage.setItem("agreementUniqueAttemptId", uniqueAttemptId);
//       localStorage.setItem("agreementOtpRetriesLeft", totalAttempts);

//       // Update state with OTP details and show OTP modal
//       setState((prevState) => ({
//         ...prevState,
//         otp: Array(6).fill(''), // Set OTP as an array of characters
//         agreementOtpExpiry: Date.now() + otpExpiry * 1000,
//         agreementOtpResendWindow: Date.now() + otpResendWindow * 1000,
//         agreementUniqueAttemptId: uniqueAttemptId,
//         maxResendAttempts: totalAttempts,
//         showOtpModal: true,
//         requestId: uniqueAttemptId, // Store request ID for future use
//       }));
//     } catch (error) {
//       handleError(error);
//     }
//   };

//   // Resend OTP
//   const handleResendOtp = async () => {
//     if (!state.isResendEnabled || state.maxResendAttempts <= 0) return;

//     console.log("Request Id", state.requestId);

//     try {
//       const response = await agreementReSendOtp(state.restaurantId, state.requestId);
//       const { otpExpiry, otpResendWindow } = response;

//       const newOtpDetails = {
//         otpExpiry: Date.now() + otpExpiry * 1000,
//         otpResendWindow: Date.now() + otpResendWindow * 1000,
//       };
//       localStorage.setItem("agreementOtpDetails", JSON.stringify(newOtpDetails));
//       // setOtpDetails(JSON.parse(localStorage.getItem("newOtpDetails")) || {});

//       setState(prevState => ({
//         ...prevState,
//         otp: Array(6).fill(''),
//         otpExpired: false,
//         isResendEnabled: false,
//         maxResendAttempts: prevState.maxResendAttempts - 1,
//       }));
//       inputRefs.current[0]?.focus();

//       setTimeout(() => setState(prevState => ({
//         ...prevState,
//         isResendEnabled: true,
//       })), otpResendWindow * 1000);

//       // Decrement resend attempts and update local storage
//       const newResendAttempts = state.maxResendAttempts - 2;
//       setState((prevState) => ({
//         ...prevState,
//         maxResendAttempts: newResendAttempts
//       }))
//       localStorage.setItem('agreementOtpRetriesLeft', newResendAttempts);

//       if (newResendAttempts > 0) {
//         setState(prevState => ({
//           ...prevState,
//           isResendEnabled: false
//         }))
//       }

//     } catch (error) {
//       handleError(error);
//     }
//   };

//   // Verify OTP
//   const handleVerifyOtp = async () => {
//     const finalOtp = state.otp.join("");

//     if (state.otpExpired) {
//       setState(prevState => ({
//         ...prevState,
//         errorMessage: "OTP expired. Please resend to get a new one."
//       }))
//       setTimeout(() => setState(prevState => ({
//         ...prevState,
//         errorMessage: ""
//       })), 5000); // Clear error after 5 seconds
//       return;
//     }

//     try {
//       const agreementData = {
//         agreementType: "Merchant_Onboarding",
//         otp: finalOtp,
//         restaurantId: state.restaurantId,
//         requestId: state.requestId,

//       }
//       const response = await preGenerateAgreementVerifyOtp(agreementData);
//       setState((prevState) => ({
//         ...prevState,
//         showOtpModal: false,
//         isOtpVerified: true,
//       }));

//       setState(prevState => ({
//         ...prevState,
//         signatureMetadataId: response.signatureMetadataId,
//         merchantEmailId: response.merchantEmailId,
//         ownerMobile: response.ownerMobile,
//       }))

//       localStorage.setItem("signatureMetadataId", response.signatureMetadataId);
//       localStorage.setItem("merchantEmailId", response.merchantEmailId);
//       localStorage.setItem("ownerMobile", response.ownerMobile);

//       const signDocumentResponse = await signMerchantAgreementDocument(
//         state.signatureMetadataId,
//         state.merchantEmailId,
//         state.ownerMobile,
//         state.restaurantId
//       );

//       localStorage.setItem("message", signDocumentResponse.body);
//       navigate('/complete-sign');

//     } catch (error) {
//       handleError(error);
//     }
//   };

//   return (
//     <div className="bg-gray-100 min-h-screen flex flex-col">
//       <main className="flex-grow flex flex-col items-center mt-20 w-full">
//         <div className="shadow-lg rounded-lg overflow-hidden flex flex-col items-center">
//           <div className="w-full">
//             <iframe
//               src="https://s3.ap-south-1.amazonaws.com/merchant-assets.thegenie.in/restaurant/MerchantAgreements/dummyRestaurant/The+Genie+Merchant+Onboarding+Documents.pdf"
//               title="Document"
//               className="w-full h-[90vh] border-0" // Set height and width for full coverage
//             ></iframe>
//           </div>
//           <div className="w-full p-6 flex flex-col items-center bg-gray-100">
//             <div className="checkbox-container flex items-center mt-4">
//               <input
//                 type="checkbox"
//                 checked={state.isChecked}
//                 onChange={() => setState((prevState) => ({ ...prevState, isChecked: !prevState.isChecked }))}
//               />
//               <label className="text-sm ml-2">I agree to the terms.</label>
//             </div>
//             <button
//               onClick={handleSendOtp}
//               disabled={!state.isChecked}
//               className={`text-white mt-5 px-4 py-2 rounded ${state.isChecked ? "bg-green-600" : "bg-gray-400 cursor-not-allowed"}`}
//             >
//               Proceed to Sign
//             </button>
//           </div>
//         </div>
//       </main>

//       {state.showOtpModal && (
//         // Otp verification section
//         <div className="fixed inset-0 flex items-center justify-center bg-gray-950 bg-opacity-75 z-50">
//           <div className="bg-white rounded-lg shadow-lg w-96 p-6">
//             <div className="flex justify-between items-center mb-4">
//               <h2 className="text-xl font-semibold">OTP Verification</h2>
//               <button
//                 onClick={() =>
//                   setState((prevState) => ({
//                     ...prevState,
//                     showOtpModal: false,
//                   }))
//                 }
//                 className="text-gray-500 hover:text-gray-700"
//               >
//                 <FontAwesomeIcon icon={faTimes} />
//               </button>
//             </div>
//             <div className="text-center">
//               <p className="text-gray-500">Check text messages for your OTP</p>
//               <div className="flex justify-center mt-4 space-x-2">
//                 {state.otp && state.otp.length > 0 && state.otp.map((digit, index) => (
//                   <input key={index} type="text" maxLength="1"
//                     className="w-10 h-10 border rounded text-center shadow-md focus:outline-none focus:ring-2 focus:ring-red-500"
//                     value={digit} onChange={(e) => handleOtpChange(index, e.target.value)}
//                     onKeyDown={(e) => handleKeyDown(index, e)}
//                     ref={(el) => inputRefs.current[index] = el} disabled={state.isOtpVerified}
//                   />
//                 ))}
//               </div>
//               {state.errorMessage && <p className="text-red-500 font-bold mt-2">{state.errorMessage}</p>}
//               <div className="mt-2">
//                 <button
//                   className={`bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded ${state.otp.join('').length === 6 ? '' : 'opacity-50 cursor-not-allowed'}`}
//                   onClick={handleVerifyOtp}
//                   disabled={state.otp.join('').length !== 6}
//                 >
//                   Verify OTP
//                 </button>
//               </div>
//               {/* Don't delete this */}
//               {/* Error Message Display */}
//               {/* {state.otpError && (
//                 <p className="text-red-500 mt-2">{state.otpError}</p> // Error message in red
//               )} */}
//               <div className="mt-2 text-gray-500">
//                 Not received OTP?{' '}
//                 <button
//                   className={`text-red-500 font-bold hover:underline ${state.isResendEnabled && state.maxResendAttempts > 0 ? '' : 'opacity-50 cursor-not-allowed'}`}
//                   onClick={handleResendOtp}
//                   disabled={!state.isResendEnabled || state.maxResendAttempts <= 0}
//                 >
//                   Resend Now
//                 </button>
//                 {state.maxResendAttempts <= 0 && (
//                   <p className="text-red-500 text-sm font-bold mt-2">Maximum resend attempts reached.</p>
//                 )}
//               </div>
//             </div>
//           </div>
//         </div>
//       )}

//     </div>
//   );
// };

// export default SignDocument;










import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { preGenerateAgreementVerifyOtp, signMerchantAgreementDocument, agreementSendOtp, agreementReSendOtp } from '../services/Api';
import { handleError } from '../services/errorHandler';
import { useNavigate } from 'react-router-dom';

const SignDocument = () => {
  const navigate = useNavigate();
  const inputRefs = useRef([]);

  const [state, setState] = useState({
    showOtpModal: false,
    otp: Array(6).fill(''),
    isOtpVerified: false,
    restaurantId: localStorage.getItem("restaurant_Id"),
    otpExpired: false,
    agreementOtpExpiry: 0,
    agreementOtpResendWindow: 0,
    maxResendAttempts: 3,
    isResendEnabled: false,
    showOtpExpiredMessage: false,
    errorMessage: "",
    isChecked: false,
    documentUrl: localStorage.getItem("documentUrl")
  });

  // Handle backspace key press in OTP fields
  const handleKeyDown = (index, event) => {
    if (event.key === 'Backspace') {
      const newOtp = [...state.otp];
      newOtp[index] = '';  // Clear current digit only

      // Update the OTP state
      setState(prevState => ({
        ...prevState,
        otp: newOtp,
      }));

      // Move focus to the previous input, but don't clear it
      if (index > 0 && state.otp[index] === '') {
        inputRefs.current[index - 1]?.focus();
      }
    }
  };

  const handleOtpChange = (index, value) => {
    if (value.length === 1 && /^\d*$/.test(value)) {
      const newOtp = [...state.otp];
      newOtp[index] = value;
      setState(prevState => ({
        ...prevState,
        otp: newOtp,
      }));

      if (index < 5) {
        inputRefs.current[index + 1]?.focus();
      }
    } else if (value === '') {
      const newOtp = [...state.otp];
      newOtp[index] = '';
      setState(prevState => ({
        ...prevState,
        otp: newOtp,
      }));
      inputRefs.current[index]?.focus();
    }
  };

  // Handle OTP Resend and Expiry Timers
  useEffect(() => {
    const currentTime = Date.now();
    const timeUntilExpiry = state.agreementOtpExpiry - currentTime;
    const timeUntilResend = state.agreementOtpResendWindow - currentTime;

    if (timeUntilExpiry > 0) {
      const expiryTimer = setTimeout(() => {
        setState((prevState) => ({
          ...prevState,
          otpExpired: true,
          showOtpExpiredMessage: true,
          isResendEnabled: prevState.maxResendAttempts > 0,
        }));
      }, timeUntilExpiry);

      // Clear timer on unmount or state change
      return () => clearTimeout(expiryTimer);
    }

    if (timeUntilResend > 0) {
      const resendTimer = setTimeout(() => {
        setState((prevState) => ({
          ...prevState,
          isResendEnabled: true,
        }));
      }, timeUntilResend);

      return () => clearTimeout(resendTimer);
    }
  }, [state.agreementOtpExpiry, state.agreementOtpResendWindow, state.maxResendAttempts]);

  const handleSendOtp = async () => {
    try {
      const response = await agreementSendOtp(state.restaurantId);
      const { otpExpiry, otpResendWindow, uniqueAttemptId, totalAttempts } = response.otp;

      localStorage.setItem("agreementOtpExpiry", Date.now() + otpExpiry * 1000);
      localStorage.setItem("agreementOtpResendWindow", Date.now() + otpResendWindow * 1000);
      localStorage.setItem("agreementUniqueAttemptId", uniqueAttemptId);
      localStorage.setItem("agreementOtpRetriesLeft", totalAttempts);

      setState((prevState) => ({
        ...prevState,
        otp: Array(6).fill(''),
        agreementOtpExpiry: Date.now() + otpExpiry * 1000,
        agreementOtpResendWindow: Date.now() + otpResendWindow * 1000,
        maxResendAttempts: totalAttempts,
        showOtpModal: true,
      }));
    } catch (error) {
      handleError(error);
    }
  };

  const handleResendOtp = async () => {
    if (!state.isResendEnabled || state.maxResendAttempts <= 0) return;

    try {
      const response = await agreementReSendOtp(state.restaurantId, localStorage.getItem("agreementUniqueAttemptId"));
      const { otpExpiry, otpResendWindow } = response;

      setState((prevState) => ({
        ...prevState,
        otp: Array(6).fill(''),
        otpExpired: false,
        isResendEnabled: false,
        agreementOtpExpiry: Date.now() + otpExpiry * 1000,
        agreementOtpResendWindow: Date.now() + otpResendWindow * 1000,
        maxResendAttempts: prevState.maxResendAttempts - 1,
      }));
      inputRefs.current[0]?.focus();
    } catch (error) {
      handleError(error);
    }
  };

  const handleVerifyOtp = async () => {
    if (state.otpExpired) {
      setState((prevState) => ({
        ...prevState,
        errorMessage: "OTP expired. Please resend to get a new one.",
      }));
      setTimeout(() => setState((prevState) => ({ ...prevState, errorMessage: "" })), 5000);
      return;
    }

    try {
      const response = await preGenerateAgreementVerifyOtp({
        agreementType: "Merchant_Onboarding",
        otp: state.otp.join(""),
        restaurantId: state.restaurantId,
        requestId: localStorage.getItem("agreementUniqueAttemptId"),
      });

      setState((prevState) => ({
        ...prevState,
        showOtpModal: false,
        isOtpVerified: true,
      }));

      const signResponse = await signMerchantAgreementDocument(
        response.signatureMetadataId,
        response.merchantEmailId,
        response.ownerMobile,
        state.restaurantId
      );

      localStorage.setItem("message", signResponse.body);
      navigate('/complete-sign');

    } catch (error) {
      handleError(error);
    }
  };

  return (
    <div className="bg-gray-100 min-h-screen flex flex-col">
      <main className="flex-grow flex flex-col items-center mt-20 w-full">
        <div className=" shadow-lg rounded-lg overflow-hidden flex flex-col items-center">
          <div className="w-full">
            <iframe
              src={state.documentUrl}
              title="Document"
              className="w-full h-screen border-0"
            ></iframe>
          </div>
          <div className="w-full p-6 flex flex-col items-center bg-gray-100">
            <div className="checkbox-container flex items-center mt-4">
              <input
                type="checkbox"
                checked={state.isChecked}
                onChange={() => setState((prevState) => ({ ...prevState, isChecked: !prevState.isChecked }))}
              />
              <label className="text-sm ml-2">I have read and understood the terms stipulated in the
                merchant agreement. I consent to the terms outlined.</label>
            </div>
            <button
              onClick={handleSendOtp}
              disabled={!state.isChecked}
              className={`text-white mt-5 px-4 py-2 rounded ${state.isChecked ? "bg-green-600" : "bg-gray-400 cursor-not-allowed"}`}
            >
              Proceed to Sign
            </button>
          </div>
        </div>
      </main>

      {state.showOtpModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-950 bg-opacity-75 z-50">
          <div className="bg-white rounded-lg shadow-lg w-96 p-6">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-semibold">OTP Verification</h2>
              <button
                onClick={() =>
                  setState((prevState) => ({
                    ...prevState,
                    showOtpModal: false,
                  }))
                }
                className="text-gray-500 hover:text-gray-700"
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
            <div className="text-center">
              <p className="text-gray-500">Check text messages for your OTP</p>
              <div className="flex justify-center mt-4 space-x-2">
                {state.otp && state.otp.length > 0 && state.otp.map((digit, index) => (
                  <input key={index} type="text" maxLength="1"
                    className="w-10 h-10 border rounded text-center shadow-md focus:outline-none focus:ring-2 focus:ring-red-500"
                    value={digit} onChange={(e) => handleOtpChange(index, e.target.value)}
                    onKeyDown={(e) => handleKeyDown(index, e)}
                    ref={(el) => inputRefs.current[index] = el} disabled={state.isOtpVerified}
                  />
                ))}
              </div>
              {state.errorMessage && <p className="text-red-500 font-bold mt-2">{state.errorMessage}</p>}
              <div className="mt-2">
                <button
                  className={`bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded ${state.otp.join('').length === 6 ? '' : 'opacity-50 cursor-not-allowed'}`}
                  onClick={handleVerifyOtp}
                  disabled={state.otp.join('').length !== 6}
                >
                  Verify OTP
                </button>
              </div>
              <div className="mt-2 text-gray-500">
                Not received OTP?{' '}
                <button
                  className={`text-red-500 font-bold hover:underline ${state.isResendEnabled && state.maxResendAttempts > 0 ? '' : 'opacity-50 cursor-not-allowed'}`}
                  onClick={handleResendOtp}
                  disabled={!state.isResendEnabled || state.maxResendAttempts <= 0}
                >
                  Resend Now
                </button>
                {state.maxResendAttempts <= 0 && (
                  <p className="text-red-500 text-sm font-bold mt-2">Maximum resend attempts reached.</p>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SignDocument;
