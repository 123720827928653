// import React, { useState, useEffect } from "react";
// import "./Registration.css";
// import { useNavigate } from "react-router-dom";
// import "react-datepicker/dist/react-datepicker.css";
// import { logoutService } from "../services/LogoutService";
// import LogoutModel from "./LogoutModel";
// import { APP_NAME, COMMISSION } from "../constants";
// import RequiredRegistrationDetails from "./RequiredRegistrationDetails";
// import ReferrerModal from "./ReferrerModal";

// const InputField = ({ label, value, id }) => (
//   <div>
//     <label htmlFor={id} className="block mt-5 text-sm font-medium text-gray-700">
//       {label}
//     </label>
//     <input
//       type="text"
//       id={id}
//       value={value}
//       readOnly
//       className="mt-1 block w-full p-2 border rounded border-gray-300 shadow-sm bg-gray-200 focus:outline-none"
//     />
//   </div>
// );

// const Contract = () => {
//   const navigate = useNavigate();
//   const [state, setState] = useState({
//     showModal: false,
//     showReferModal: false,
//     showLogoutModal: false,
//     restaurantName: "",
//     ownerName: "",
//     businessAddress: "123 Main St",
//     gstNumber: "123456789012",
//     bankDetails: "Bank Account Details",
//     fssaiDetails: "FSSAI Details",
//     isChecked: false,
//     isFormValid: false,
//   });

//   useEffect(() => {
//     const token = localStorage.getItem("accessToken");
//     const restaurantInfo = JSON.parse(localStorage.getItem("restaurantInfoData"));

//     if (!token) {
//       navigate("/");
//     } else if (restaurantInfo) {
//       setState((prevState) => ({
//         ...prevState,
//         restaurantName: restaurantInfo.restaurant_name,
//         ownerName: restaurantInfo.owner_full_name,
//       }));
//     }
//   }, [navigate]);

//   const toggleModal = (modal) => {
//     setState((prevState) => ({ ...prevState, [modal]: !prevState[modal] }));
//   };

//   const handleCheckboxChange = () => {
//     setState((prevState) => ({
//       ...prevState,
//       isChecked: !prevState.isChecked,
//       isFormValid: !prevState.isChecked,
//     }));
//   };

//   const handleNextClick = () => {
//     if (state.isFormValid) {
//       navigate("/sign-document");
//     }
//   };

//   const confirmLogout = () => {
//     toggleModal("showLogoutModal");
//     logoutService("Session");
//   };

//   return (
//     <div className="min-h-screen bg-gray-100 flex flex-col">
//       <main className="flex-grow flex pt-20 pb-20 w-full">
//         <div className="content flex-1 overflow-y-auto bg-gray-100 px-6 py-1 sm:px-6">
//           <h3 className="mt-5 text-3xl font-bold">Review merchant details</h3>

//           <InputField label="Restaurant Name*" value={state.restaurantName} id="restaurantName" />
//           <InputField label="Owner Name*" value={state.ownerName} id="ownerName" />
//           <InputField label="Business Address" value={state.businessAddress} id="businessAddress" />
//           <InputField label="GST Number" value={state.gstNumber} id="gstNumber" />
//           <InputField label="Bank Account Details" value={state.bankDetails} id="bankDetails" />
//           <InputField label="FSSAI Details" value={state.fssaiDetails} id="fssaiDetails" />

//           <p className="mt-5 text-xl sm:text-2xl font-bold text-center sm:text-left">
//             In {APP_NAME}, we are providing very low commission, only {COMMISSION}
//           </p>

//           <form className="mt-5">
//             <div className="checkbox-container flex items-center">
//               <input
//                 type="checkbox"
//                 id="terms"
//                 name="terms"
//                 className="mr-2"
//                 checked={state.isChecked}
//                 onChange={handleCheckboxChange}
//               />
//               <label htmlFor="terms" className="text-sm">
//                 I agree to the <a href="#" className="text-blue-500 underline">terms and conditions</a>
//               </label>
//             </div>
//           </form>

//           <div className="flex flex-col sm:flex-row mt-5 space-y-3 sm:space-y-0 sm:space-x-3">
//             <button
//               className="text-white text-center rounded px-5 py-2 bg-green-600"
//               onClick={() => navigate("/restaurant-documents")}
//             >
//               Back
//             </button>

//             <button
//               onClick={handleNextClick}
//               disabled={!state.isFormValid}
//               className={`text-white text-center rounded px-5 py-2 ${state.isFormValid ? "bg-green-600" : "bg-gray-400 cursor-not-allowed"}`}
//             >
//               Proceed to Sign
//             </button>
//           </div>
//         </div>
//       </main>

//       {/* Modal Components */}
//       {state.showModal && <RequiredRegistrationDetails toggleModal={() => toggleModal("showModal")} />}
//       {state.showReferModal && (
//         <ReferrerModal
//           toggleReferModal={() => toggleModal("showReferModal")}
//           onClickSuccess={() => toggleModal("showReferModal")}
//         />
//       )}
//       {state.showLogoutModal && (
//         <LogoutModel
//           onClickCancel={confirmLogout}
//           onClickLogout={() => toggleModal("showLogoutModal")}
//         />
//       )}
//     </div>
//   );
// };

// export default Contract;
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Registration.css";
import { APP_NAME, COMMISSION } from "../constants";
import { logoutService } from "../services/LogoutService";
import RequiredRegistrationDetails from "./RequiredRegistrationDetails";
import ReferrerModal from "./ReferrerModal";
import LogoutModel from "./LogoutModel";
import { generateMerchantAgreementDocument, preGenerateAgreement } from "../services/Api";

// Input component for displaying static fields
const InputField = ({ label, value, id }) => (
  <div>
    <label htmlFor={id} className="block mt-5 text-sm font-medium text-gray-700">
      {label}
    </label>
    <input
      type="text"
      id={id}
      value={value}
      readOnly
      className="mt-1 block w-full p-2 border rounded border-gray-300 shadow-sm bg-gray-200 focus:outline-none"
    />
  </div>
);

const Contract = () => {
  const navigate = useNavigate();
  const [state, setState] = useState({
    showModal: false,
    showReferModal: false,
    showLogoutModal: false,
    restaurantName: localStorage.getItem("restaurantName"),
    ownerName: localStorage.getItem("ownerName"),
    businessAddress: localStorage.getItem("businessAddress"),
    gstNumber: localStorage.getItem("gstNumber"),
    bankDetails: localStorage.getItem("accountNumber"),
    fssaiDetails: localStorage.getItem("fssaiNumber"),
    isChecked: false,
    isFormValid: false,
  });

  // UseEffect to retrieve data from local storage and check token
  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    const restaurantInfo = JSON.parse(localStorage.getItem("restaurantInfoData"));

    if (!token) {
      navigate("/");
    } else if (restaurantInfo) {
      setState((prevState) => ({
        ...prevState,
        restaurantName: restaurantInfo.restaurant_name,
        ownerName: restaurantInfo.owner_full_name,
      }));
    }
  }, [navigate]);

  // // API to call Pre-Generate Agreement
  // const callPreGenerateAgreement = async () => {
  //   try {
  //     const response = await preGenerateAgreement();

  //     // Save the metadata ID in localStorage for reference
  //     if (response.requestMetaDataId) {
  //       localStorage.setItem("AgreementMetaDataId", response.requestMetaDataId);

  //       // Automatically call the document generation function
  //       const documentResponse = await generateMerchantAgreementDocument(response.requestMetaDataId);
  //       navigate("/sign-document");
  //     } else if (response.done === "signature" && response.verification_document_url) {
  //       // Document already exists, open it in a new tab
  //       window.open(response.data.verification_document_url, "_blank");

  //       navigate("/sign-document");
  //     }
  //   } catch (error) {
  //     console.error("Error in Pre-Generate Agreement:", error);
  //     throw error;
  //   }
  // };

  // // Updated callPreGenerateAgreement Function with CORS-Anywhere for debugging
  // const callPreGenerateAgreement = async () => {
  //   try {
  //     const response = await preGenerateAgreement();

  //     if (response.requestMetaDataId) {
  //       localStorage.setItem("AgreementMetaDataId", response.requestMetaDataId);

  //       // // Replace generateMerchantAgreementDocument call with fetch using CORS proxy
  //       // const documentResponse = await fetch(
  //       //   `https://cors-anywhere.herokuapp.com/https://kcxlygodyrq3deqjv6j34b5d7m0wiuex.lambda-url.ap-south-1.on.aws/`,
  //       //   {
  //       //     method: "POST",
  //       //     headers: { "Content-Type": "application/json" },
  //       //     body: JSON.stringify({ requestMetaDataId: response.requestMetaDataId }),
  //       //     mode: "cors",
  //       //     credentials: "include",
  //       //   }
  //       // ).then((res) => res.json());

  //       // const responses = await fetch(`https://cors-anywhere.herokuapp.com/https://kcxlygodyrq3deqjv6j34b5d7m0wiuex.lambda-url.ap-south-1.on.aws/`, {
  //       //   method: "POST",
  //       //   headers: { "Content-Type": "application/json" },
  //       //   body: JSON.stringify({ requestMetaDataId: response.requestMetaDataId }),
  //       // });
  //       const documentResponse = await generateMerchantAgreementDocument(response.requestMetaDataId);
  //       navigate("/sign-document");

  //     } else if (docum.done === "signature" && response.verification_document_url) {
  //       // window.open(response.data.verification_document_url, "_blank");
  //       console.log(response.verification_document_url);
  //       localStorage.setItem("documentUrl", response.verification_document_url)

  //       navigate("/sign-document");
  //     }
  //   } catch (error) {
  //     console.error("Error in Pre-Generate Agreement:", error);
  //     throw error;
  //   }
  // };

  // Updated callPreGenerateAgreement Function
  const callPreGenerateAgreement = async () => {
    try {
      const response = await preGenerateAgreement();

      if (response?.requestMetaDataId) {
        localStorage.setItem("AgreementMetaDataId", response.requestMetaDataId);

        const documentResponse = await generateMerchantAgreementDocument(response.requestMetaDataId);

        if (documentResponse?.done === "signature" && documentResponse?.verification_document_url) {
          localStorage.setItem("documentUrl", documentResponse.verification_document_url);
          navigate("/sign-document");
        }
      } else {
        console.error("No requestMetaDataId found in the response");
      }
    } catch (error) {
      console.error("Error in Pre-Generate Agreement:", error);
      throw error;
    }
  };



  // Handle checkbox to enable/disable the Proceed button
  const handleCheckboxChange = () => {
    setState((prevState) => ({
      ...prevState,
      isChecked: !prevState.isChecked,
      isFormValid: !prevState.isChecked,
    }));
  };

  // Handle "Proceed to Sign" button click
  const handleNextClick = () => {
    if (state.isFormValid) {
      callPreGenerateAgreement(); // Trigger the first API on button click
    }
  };

  // Confirm and execute logout
  const confirmLogout = () => {
    toggleModal("showLogoutModal");
    logoutService("Session");
  };

  // Toggle modal visibility
  const toggleModal = (modal) => {
    setState((prevState) => ({ ...prevState, [modal]: !prevState[modal] }));
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <main className="flex-grow flex pt-20 pb-20 w-full">
        <div className="content flex-1 overflow-y-auto bg-gray-100 px-6 py-1 sm:px-6">
          <h3 className="mt-5 text-3xl font-bold">Review merchant details</h3>

          {/* Display Input Fields */}
          <InputField label="Restaurant Name*" value={state.restaurantName} id="restaurantName" />
          <InputField label="Owner Name*" value={state.ownerName} id="ownerName" />
          <InputField label="Business Address" value={state.businessAddress} id="businessAddress" />
          <InputField label="GST Number" value={state.gstNumber} id="gstNumber" />
          <InputField label="Bank Account Details" value={state.bankDetails} id="bankDetails" />
          <InputField label="FSSAI Details" value={state.fssaiDetails} id="fssaiDetails" />

          <p className="mt-5 text-xl sm:text-2xl font-bold text-center sm:text-left">
            In {APP_NAME}, we are providing very low commission, only {COMMISSION}
          </p>

          {/* Terms and Conditions Checkbox */}
          <form className="mt-5">
            <div className="checkbox-container flex items-center">
              <input
                type="checkbox"
                id="terms"
                name="terms"
                className="mr-2"
                checked={state.isChecked}
                onChange={handleCheckboxChange}
              />
              <label htmlFor="terms" className="text-sm">
                I agree to the <a href="#" className="text-blue-500 underline">terms and conditions</a>
              </label>
            </div>
          </form>

          {/* Navigation Buttons */}
          <div className="flex flex-col sm:flex-row mt-5 space-y-3 sm:space-y-0 sm:space-x-3">
            <button
              className="text-white text-center rounded px-5 py-2 bg-green-600"
              onClick={() => navigate("/restaurant-documents")}
            >
              Back
            </button>

            <button
              onClick={handleNextClick}
              disabled={!state.isFormValid}
              className={`text-white text-center rounded px-5 py-2 ${state.isFormValid ? "bg-green-600" : "bg-gray-400 cursor-not-allowed"}`}
            >
              Proceed to Sign
            </button>
          </div>
        </div>
      </main>

      {/* Modal Components */}
      {state.showModal && <RequiredRegistrationDetails toggleModal={() => toggleModal("showModal")} />}
      {state.showReferModal && (
        <ReferrerModal
          toggleReferModal={() => toggleModal("showReferModal")}
          onClickSuccess={() => toggleModal("showReferModal")}
        />
      )}
      {state.showLogoutModal && (
        <LogoutModel
          onClickCancel={confirmLogout}
          onClickLogout={() => toggleModal("showLogoutModal")}
        />
      )}
    </div>
  );
};

export default Contract;
