// services/api.js
import axios from "axios";
import { refreshTokenAPI } from "../services/AuthApi";
import { logoutService } from "./LogoutService";

// Function to get the latest base URL from localStorage
const getBaseUrl = () => {
  const endpoint = localStorage.getItem("endpoint");
  return `${process.env.REACT_APP_API_ENDPOINT}/${endpoint || ""}`;
};

const agreementGenerationEndpoint =
  process.env.REACT_APP_AGREEMENT_GENERATION_LAMBDA_ENDPOINT || "";

const verificationEndpoint =
  process.env.REACT_APP_VERIFICATION_LAMBA_ENDPOINT || "";

// Create Axios instance with a dynamic base URL
const ApiService = axios.create({
  baseURL: getBaseUrl(),
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  },
});

// Function to update the base URL when the endpoint changes
export const updateApiBaseUrl = () => {
  ApiService.defaults.baseURL = getBaseUrl();
};

// Helper function to parse the expiry time string and return a Date object
const parseExpiryTime = (expiryString) => {
  return new Date(expiryString.replace(" ", "T"));
};

let currentTimeIntervalId;
let tokenRefreshIntervalId;

// Update current time in localStorage every second
currentTimeIntervalId = setInterval(() => {
  const now = new Date();
  localStorage.setItem("currentTime", now.getTime().toString()); // Store the timestamp in milliseconds
}, 1000);

// Background function to refresh token periodically
const startTokenRefreshTimer = () => {
  const interval = 60000; // 1 minute interval for checking

  tokenRefreshIntervalId = setInterval(async () => {
    const accessTokenExpiry = localStorage.getItem("accessTokenExpiry");
    const refreshToken = localStorage.getItem("refreshToken");
    const refreshTokenExpiry = localStorage.getItem("refreshTokenExpiry");

    const accessTokenExpiryDate = accessTokenExpiry
      ? parseExpiryTime(accessTokenExpiry)
      : null;
    const refreshTokenExpiryDate = refreshTokenExpiry
      ? parseExpiryTime(refreshTokenExpiry)
      : null;

    const now = new Date(parseInt(localStorage.getItem("currentTime")));

    if (accessTokenExpiryDate && accessTokenExpiryDate - now < 65000) {
      // If access token is about to expire
      if (refreshToken && refreshTokenExpiryDate > now) {
        try {
          const response = await refreshTokenAPI(refreshToken);
          if (response && response.accessToken) {
            localStorage.setItem("accessToken", response.accessToken);
            localStorage.setItem("refreshToken", response.refreshToken);
            localStorage.setItem(
              "accessTokenExpiry",
              response.accessTokenExpiry
            );
            localStorage.setItem(
              "refreshTokenExpiry",
              response.refreshTokenExpiry
            );

            // Update ApiService default header with new access token
            ApiService.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${response.accessToken}`;

            // Stop all intervals after successful token refresh
            clearInterval(currentTimeIntervalId);
            clearInterval(tokenRefreshIntervalId);
          } else {
            logoutService("Session");
          }
        } catch (error) {
          logoutService("Session");
        }
      } else {
        // Refresh token expired
        logoutService("Session");
      }
    }
  }, interval);
};

// Initialize background refresh timer
startTokenRefreshTimer();

// Axios interceptor to set Authorization header
ApiService.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const logout = async (logoutType) => {
  try {
    // Manually specify the base URL for logout
    const customBaseUrl = `${process.env.REACT_APP_API_ENDPOINT}/api`;
    const response = await axios.post(`${customBaseUrl}auth/logout`, {
      logoutType,
    });
    // const response = await ApiService.post("/logout", { logoutType });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const restaurantId = localStorage.getItem("restaurant_Id");

// Function to check onboarding status by restaurant ID
export const checkOnboardingStatus = async (restaurantId) => {
  try {
    const response = await ApiService.get(`/restaurants/onboarding/status`, {
      params: { restaurantId: restaurantId },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Function to retrieve verification status by restaurant ID
export const getVerificationStatus = async (restaurantId) => {
  try {
    const response = await ApiService.get(
      `/restaurants/onboarding/verificationStatus`,
      {
        params: { restaurantId: restaurantId },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchActiveSearchCategories = async () => {
  try {
    const response = await ApiService.get(
      "/restaurants/active-search-categories"
    );
    return response.data.search_categories;
  } catch (error) {
    throw error;
  }
};

export const uploadPanImage = async (fileType, restaurantId) => {
  try {
    const response = await ApiService.get(`/restaurants/register/pan-upload`, {
      params: { fileType, restaurantId: restaurantId },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const confirmPanUpload = async (
  metadataId,
  isUploadSuccess,
  restaurantId
) => {
  try {
    const response = await ApiService.post(
      `/restaurants/register/pan-upload/upload-confirmation`,
      { metadataId, isUploadSuccess, restaurantId }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchPanUrl = async (restaurantId) => {
  try {
    const response = await ApiService.get(
      `/restaurants/register/pan-url/fetch`,
      {
        params: { restaurantId: restaurantId },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deletePanUrl = async (url, restaurantId) => {
  try {
    const response = await ApiService.post(`/restaurants/register/pan/delete`, {
      url,
      restaurantId: restaurantId,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const uploadGstImage = async (fileType, restaurantId) => {
  try {
    const response = await ApiService.get(`/restaurants/register/gst-upload`, {
      params: { fileType, restaurantId: restaurantId },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const confirmGstUpload = async (
  metadataId,
  isUploadSuccess,
  restaurantId
) => {
  try {
    const response = await ApiService.post(
      `/restaurants/register/gst-upload/upload-confirmation`,
      { metadataId, isUploadSuccess, restaurantId }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchGstUrl = async (restaurantId) => {
  try {
    const response = await ApiService.get(
      `/restaurants/register/gst-url/fetch`,
      {
        params: { restaurantId: restaurantId },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteGstUrl = async (url, restaurantId) => {
  try {
    const response = await ApiService.post(`/restaurants/register/gst/delete`, {
      url,
      restaurantId: restaurantId,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const uploadFssaiImage = async (fileType, restaurantId) => {
  try {
    const response = await ApiService.get(
      `/restaurants/register/fssai-upload`,
      {
        params: { fileType, restaurantId: restaurantId },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const confirmFssaiUpload = async (
  metadataId,
  isUploadSuccess,
  restaurantId
) => {
  try {
    const response = await ApiService.post(
      `/restaurants/register/fssai-upload/upload-confirmation`,
      {
        metadataId,
        isUploadSuccess,
        restaurantId,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchFssaiUrl = async (restaurantId) => {
  try {
    const response = await ApiService.get(
      `/restaurants/register/fssai-url/fetch`,
      {
        params: { restaurantId: restaurantId },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteFssaiUrl = async (url, restaurantId) => {
  try {
    const response = await ApiService.post(
      `/restaurants/register/fssai/delete`,
      { url, restaurantId }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Generate Store Image Upload Presigned URL
export const uploadStoreImage = async (fileType, restaurantId) => {
  try {
    const response = await ApiService.get("/restaurants/store-image/upload", {
      params: { fileType, restaurantId },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Confirm Store Image Upload
export const confirmStoreImageUpload = async (
  metadataId,
  isUploadSuccess,
  restaurantId
) => {
  try {
    const response = await ApiService.post("/restaurants/store-image/confirm", {
      metadataId,
      isUploadSuccess,
      restaurantId,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Generate Profile Image Upload Presigned URL
export const uploadProfileImage = async (fileType, restaurantId) => {
  try {
    const response = await ApiService.get("/restaurants/profile-image/upload", {
      params: { fileType, restaurantId },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Confirm Profile Image Upload
export const confirmProfileImageUpload = async (
  metadataId,
  isUploadSuccess,
  restaurantId
) => {
  try {
    const response = await ApiService.post(
      "/restaurants/profile-image/confirm",
      { metadataId, isUploadSuccess, restaurantId }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Generate Food Image Upload Presigned URL
export const uploadFoodImage = async (fileType, restaurantId) => {
  try {
    const response = await ApiService.get("/restaurants/food-image/upload", {
      params: { fileType, restaurantId },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Confirm Food Image Upload
export const confirmFoodImageUpload = async (
  metadataId,
  isUploadSuccess,
  restaurantId
) => {
  try {
    const response = await ApiService.post("/restaurants/food-image/confirm", {
      metadataId,
      isUploadSuccess,
      restaurantId,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Generate Delivery Menu Image Upload Presigned URL
export const uploadDeliveryMenuImage = async (fileType, restaurantId) => {
  try {
    const response = await ApiService.get(
      "/restaurants/delivery-menu-image/upload",
      {
        params: { fileType, restaurantId },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Confirm Delivery Menu Image Upload
export const confirmDeliveryMenuImageUpload = async (
  metadataId,
  isUploadSuccess,
  restaurantId
) => {
  try {
    const response = await ApiService.post(
      "/restaurants/delivery-menu-image/confirm",
      { metadataId, isUploadSuccess, restaurantId }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Fetch Store Image URLs
export const fetchStoreImages = async (restaurantId) => {
  try {
    const response = await ApiService.get("/restaurants/store-image/fetch", {
      // params: { restaurantId },
      params: { restaurantId: restaurantId },
    });
    return response.data.storeImageUrls;
  } catch (error) {
    throw error;
  }
};

// Delete Store Image
export const deleteStoreImage = async (url, restaurantId) => {
  try {
    const response = await ApiService.post("/restaurants/store-image/delete", {
      url,
      restaurantId: restaurantId,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Fetch Profile Image URL
export const fetchProfileImage = async (restaurantId) => {
  try {
    const response = await ApiService.get("/restaurants/profile-image/fetch", {
      // params: { restaurantId },
      params: { restaurantId: restaurantId },
    });
    return response.data.profileImageUrl;
  } catch (error) {
    throw error;
  }
};

// Delete Profile Image
export const deleteProfileImage = async (url, restaurantId) => {
  try {
    const response = await ApiService.post(
      "/restaurants/profile-image/delete",
      { url, restaurantId }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Fetch Food Image URLs
export const fetchFoodImages = async (restaurantId) => {
  try {
    const response = await ApiService.get("/restaurants/food-image/fetch", {
      // params: { restaurantId },
      params: { restaurantId: restaurantId },
    });
    return response.data.foodImageUrls;
  } catch (error) {
    throw error;
  }
};

// Delete Food Image
export const deleteFoodImage = async (url, restaurantId) => {
  try {
    const response = await ApiService.post("/restaurants/food-image/delete", {
      url,
      restaurantId: restaurantId,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Fetch Delivery Menu Image URLs
export const fetchDeliveryMenuImages = async (restaurantId) => {
  try {
    const response = await ApiService.get(
      "/restaurants/delivery-menu-image/fetch",
      {
        // params: { restaurantId },
        params: { restaurantId: restaurantId },
      }
    );
    return response.data.deliveryMenuImageUrls;
  } catch (error) {
    throw error;
  }
};

// Delete Delivery Menu Image
export const deleteDeliveryMenuImage = async (url, restaurantId) => {
  try {
    const response = await ApiService.post(
      "/restaurants/delivery-menu-image/delete",
      { url, restaurantId }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Verification KYC ALL

// PreVerify function to hit the /preVerify endpoint
const preVerify = async (
  verificationRequestType,
  restaurantId,
  requestData
) => {
  try {
    const response = await ApiService.post("restaurants/onboarding/preVerify", {
      verificationRequestType,
      restaurantId,
      requestData,
    });

    // Extract the metadata ID from the response
    const metadataId = response.data.requestMetaDataId;

    // Trigger the KYC verification in the background
    kycVerify(metadataId);

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

// KYC Verification function to hit the AWS Lambda endpoint using metadata ID
const kycVerify = async (metadataId) => {
  try {
    const kycResponse = await axios.post(
      verificationEndpoint,
      {
        kyc_verification_metadata_id: metadataId,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Requested-With": "XMLHttpRequest", // Ensure the header is included
        },
        withCredentials: true, // Include credentials if needed
      }
    );
    return kycResponse;
  } catch (error) {
    handleErrorResponse(error);
  }
};

// Function to handle and log different error responses
const handleErrorResponse = (error) => {
  if (error.response) {
    throw new Error(error.response.data.message || "Request failed.");
  } else if (error.request) {
    throw new Error("No response from server.");
  } else {
    throw new Error("An unexpected error occurred.");
  }
};

// Functions for specific document verifications

// PAN Verification
export const verifyPAN = async (panNumber, fullName, restaurantId) => {
  const requestData = { panNumber, fullName };
  return await preVerify("PAN", restaurantId, requestData);
};

// GST Verification
export const verifyGST = async (gstNumber, businessAddress, restaurantId) => {
  const requestData = { gstNumber, businessAddress };
  return await preVerify("GST", restaurantId, requestData);
};

// FSSAI Verification
export const verifyFSSAI = async (fssaiNumber, expiryDate, restaurantId) => {
  const requestData = { fssaiNumber, expiryDate };
  return await preVerify("FSSAI", restaurantId, requestData);
};

// BANK Verification
export const verifyBANK = async (bankAcNumber, ifsc_code, account_type, restaurantId) => {
  const requestData = { bankAcNumber, ifsc_code, account_type };
  return await preVerify("BANK", restaurantId, requestData);
};

export const sendOtpToOwner = async (phoneNumber, restaurantId, mode) => {
  try {
    const response = await ApiService.post(
      "/restaurants/restaurant-number-preverification",
      {
        phoneNumber,
        restaurantId,
        mode,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const reSendOtpToOwner = async (
  phoneNumber,
  restaurantId,
  mode,
  requestId
) => {
  try {
    const response = await ApiService.post(
      "/restaurants/restaurant-number-preverification",
      {
        phoneNumber,
        restaurantId,
        mode,
        requestId,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const verifyOwnerOtp = async (phoneNumber, otp) => {
  try {
    const response = await ApiService.post(
      "/restaurants/restaurant-number-verification",
      {
        phoneNumber,
        otp,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// For sending otp after signing document
export const sendOtp = async (restaurantId) => {
  try {
    const response = await ApiService.post(
      "/restaurants/onboarding/agreement-auth",
      {
        agreementType: "Merchant_Onboarding",
        restaurantId,
      }
    );
    return response.data;
  } catch (error) {
    throw error; // Will be caught by the error handler
  }
};

// For storing Restaurant Info data
export const updateRestaurantInfo = async (restaurantData, restaurantId) => {
  try {
    const response = await ApiService.post("/restaurants/onboarding/data", {
      requestPage: "resInfo",
      restaurantId: restaurantId,
      data: restaurantData,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// For storing Restaurant Info data
export const readRestaurantInfo = async (restaurantId) => {
  try {
    const response = await ApiService.get("/restaurants/onboarding/data", {
      params: { requestPage: "resInfo", restaurantId: restaurantId },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// For storing Menu Operation data
export const updateMenuOperation = async (restaurantData, restaurantId) => {
  try {
    const response = await ApiService.post("/restaurants/onboarding/data", {
      requestPage: "menuOpsDetails",
      restaurantId: restaurantId,
      data: restaurantData,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// For storing Menu Operation Info data
export const readMenuOperation = async (restaurantId) => {
  try {
    const response = await ApiService.get("/restaurants/onboarding/data", {
      params: { requestPage: "menuOpsDetails", restaurantId: restaurantId },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// API function to update restaurant documents
export const updateRestaurantDocuments = async (documentData, restaurantId) => {
  try {
    const response = await ApiService.post("/restaurants/onboarding/data", {
      requestPage: "resDocuments", // Page type for context
      data: documentData, // Passing the document data
      restaurantId: restaurantId,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// For storing Restaurant Documents Info data
export const readRestaurantDocuments = async (restaurantId) => {
  try {
    const response = await ApiService.get("/restaurants/onboarding/data", {
      params: { requestPage: "resDocuments", restaurantId: restaurantId },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// For adding User Referral during Onboarding
export const addUserReferralForOnboarding = async (
  restaurantId,
  referrerMobileNumber
) => {
  try {
    const response = await ApiService.post("/restaurants/onboarding/referral", {
      restaurantId: restaurantId,
      referrerMobileNumber: referrerMobileNumber,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// For fetching User Referrals during Onboarding
export const fetchUserReferralsForOnboarding = async (restaurantId) => {
  try {
    const response = await ApiService.get(`/restaurants/onboarding/referral`, {
      params: {
        restaurantId: restaurantId,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Api.js (API Service File)

export const deleteUserReferralsForOnboarding = async (
  restaurantId,
  referralMobileNumber
) => {
  try {
    const response = await ApiService.delete(
      `/restaurants/onboarding/referral`,
      {
        data: {
          // Specify the data key to include payload in DELETE request
          restaurantId: restaurantId,
          referralMobileNumber: referralMobileNumber,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

// Agreements Related

// Function to pre-generate the agreement during merchant onboarding
export const preGenerateAgreement = async () => {
  try {
    const response = await ApiService.post(
      `/restaurants/agreements/preGenerate`,
      {
        agreementType: "Merchant_Onboarding",
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Function to handle the merchant agreement and payout document generation
export const generateMerchantAgreementDocument = async (requestMetaDataId) => {
  try {
    const response = await axios.post(
      `https://kcxlygodyrq3deqjv6j34b5d7m0wiuex.lambda-url.ap-south-1.on.aws/`,
      { requestMetaDataId },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Requested-With": "XMLHttpRequest", // Ensure the header is included
        },
        withCredentials: true, // Include credentials if needed
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// For sending otp after signing document
export const agreementSendOtp = async (restaurantId) => {
  try {
    const response = await ApiService.post(
      "/restaurants/onboarding/agreement-auth",
      {
        agreementType: "Merchant_Onboarding",
        restaurantId,
        mode: "send",
      }
    );
    return response.data;
  } catch (error) {
    throw error; // Will be caught by the error handler
  }
};

// For sending otp after signing document
export const agreementReSendOtp = async (restaurantId, requestId) => {
  try {
    const response = await ApiService.post(
      "/restaurants/onboarding/agreement-auth",
      {
        agreementType: "Merchant_Onboarding",
        restaurantId,
        mode: "resend",
        requestId,
      }
    );
    return response.data;
  } catch (error) {
    throw error; // Will be caught by the error handler
  }
};

export const preGenerateAgreementVerifyOtp = async (agreementData) => {
  try {
    const response = await ApiService.post(
      "/restaurants/onboarding/signature-agreement",
      agreementData
    );
    return response.data; // Returns the signature metadata ID and merchant email if successful
  } catch (error) {
    throw error; // Rethrow the error if it's not handled above
  }
};

// Function to generate and sign the merchant agreement document
export const signMerchantAgreementDocument = async (
  merchantAgreementSignatureMetadataId,
  ownerEmailId,
  ownerMobile,
  restaurantId
) => {
  try {
    const requestBody = {
      merchantAgreementSignatureMetadataId,
      ownerEmailId,
      ownerMobile,
      restaurantId,
    };

    const response = await axios.post(
      `https://n7ugw6mdl5lnifdck7rd7qclwi0sgpvi.lambda-url.ap-south-1.on.aws/`,
      requestBody,
      {
        headers: { "Content-Type": "application/json" },
        withCredentials: true, // Include credentials if needed
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
