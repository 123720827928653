import React, { useState, useEffect, useRef } from "react";
import "./Registration.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { logoutService } from "../services/LogoutService";
import LogoutModel from "./LogoutModel";
import { APP_NAME } from "../constants";
import RequiredRegistrationDetails from "./RequiredRegistrationDetails";
import ReferrerModal from "./ReferrerModal";
import { checkOnboardingStatus, readRestaurantInfo, reSendOtpToOwner, updateRestaurantInfo } from "../services/Api";
import { sendOtpToOwner, verifyOwnerOtp } from "../services/Api";
import { handleError } from "../services/errorHandler";
import { fetchCurrentUTCTime } from "../services/AuthApi";
// import LocationPicker from "./LocationPicker";
import { FaCrosshairs } from "react-icons/fa";
import { GoogleMap, useJsApiLoader, Autocomplete } from "@react-google-maps/api";
import axios from "axios";

const Registration = () => {
  const navigate = useNavigate();
  const inputRefs = useRef([]);

  const [state, setState] = useState({
    restaurantName: "",
    restaurantNameError: '', // Error message for restaurant name
    inputsDisabled: false,
    ownerName: "",
    ownerNameError: "",
    ownerEmailError: "",
    restaurantPhoneNumber: "",
    isPhoneFetched: false,
    ownerEmail: "",
    shopNo: "",
    floor: "",
    area: "",
    isSameAsOwner: false,
    showModal: false,
    showReferModal: false,
    showOtpModal: false,
    isOtpValid: false,
    otp: Array(6).fill(''),
    isOtpVerified: false,
    otpError: "",
    showLogoutModal: false,
    isFormValid: false,
    restaurantId: localStorage.getItem("restaurant_Id"),
    latitude: "",
    longitude: "",
    cityState: "",
    zipCode: "",
    otpExpired: false,
    restaurantNumberOtpExpiry: "",
    restaurantNumberOtpResendWindow: "",
    restaurantNumberUniqueAttemptId: "",
    restaurantNumberOtpRetriesLeft: "",
    maxResendAttempts: "",
    isResendEnabled: false,
    showOtpExpiredMessage: false,
    errorMessage: "",
    showMapModal: '',
    zipCodeError: "",
    cityStateError: "",
    isFormValid: false,
    inputsDisabled: false,
    showMapModal: false,
  });

  const [saveMessage, setSaveMessage] = useState("");

  // Validate fields: common validation function
  const validateFields = () => {
    let errors = {};

    // Restaurant Name validation: no numbers or special characters
    if (!state.restaurantName.trim()) {
      errors.restaurantNameError = "Restaurant name cannot be empty.";
    } else if (!/^[A-Za-z\s]*$/.test(state.restaurantName)) {
      errors.restaurantNameError = "Restaurant name can only contain letters and spaces.";
    }

    // Owner Name validation: no numbers or special characters
    if (!state.ownerName.trim()) {
      errors.ownerNameError = "Owner name cannot be empty.";
    } else if (!/^[A-Za-z\s]*$/.test(state.ownerName)) {
      errors.ownerNameError = "Owner name can only contain letters and spaces.";
    }

    // Email validation
    if (!state.ownerEmail.trim()) {
      errors.ownerEmailError = "Owner email cannot be empty.";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/.test(state.ownerEmail)) {
      errors.ownerEmailError = "Please enter a valid email address.";
    }

    // Area validation
    if (!state.area.trim()) {
      errors.areaError = "Area cannot be empty.";
    } else if (/[^A-Za-z0-9\s]/.test(state.area)) {
      errors.areaError = "Area cannot contain special characters.";
    }

    // City/State validation
    if (!state.cityState.trim()) {
      errors.cityStateError = "City and state cannot be empty.";
    } else if (/[^A-Za-z\s,]/.test(state.cityState)) {
      errors.cityStateError = "City and state can only contain letters and commas.";
    }

    // // Zip code validation
    // if (!state.zipCode.trim()) {
    //   errors.zipCodeError = "Zip code cannot be empty.";
    // } else if (!/^\d{6}$/.test(state.zipCode)) {
    //   errors.zipCodeError = "Invalid zip code format. Zip code must be 6 digits.";
    // }

    // Update state with errors
    setState(prevState => ({
      ...prevState,
      ...errors,
    }));

    // Check if form is valid
    return Object.keys(errors).length === 0;
  };

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value) && value.length <= 10) {
      setState(prevState => ({
        ...prevState,
        restaurantPhoneNumber: value,
      }));
    }
  };

  useEffect(() => {
    const syncTime = async () => {
      try {
        const response = await fetchCurrentUTCTime();  // Fetch the UTC time from API
        const utcTime = response.data.utcTime;
        const utcTimestamp = new Date(utcTime);  // Convert fetched time to Date object
        localStorage.setItem("currentTime", utcTimestamp.getTime().toString()); // Store timestamp in milliseconds
      } catch (error) {
        console.error("Failed to sync time:", error);
      }
    };
    syncTime(); // Sync the time initially
  }, []);

  // Logout Modal
  const confirmLogout = () => {
    setState((prevState) => ({
      ...prevState,
      showLogoutModal: false,
    }));
    logoutService("Session");
  };
  // For displaying modal - Mandatory modal
  const toggleModal = () => {
    setState((prevState) => ({
      ...prevState,
      showModal: !prevState.showModal,
    }));
  };

  // For displaying referral modal
  const toggleReferModal = () => {
    setState((prevState) => ({
      ...prevState,
      showReferModal: !prevState.showReferModal
    }));
  };

  const handleOtpChange = (index, value) => {
    if (value.length === 1 && /^\d*$/.test(value)) {
      const newOtp = [...state.otp];
      newOtp[index] = value;
      setState(prevState => ({
        ...prevState,
        otp: newOtp,  // Updates the OTP in the state
        isOtpValid: newOtp.join('').length === 6 // Check if OTP is fully entered
      }));

      if (index < 5) {
        inputRefs.current[index + 1]?.focus();  // Focus on the next input field
      }
    } else if (value === '') {
      const newOtp = [...state.otp];
      newOtp[index] = '';  // Clear the current input
      setState(prevState => ({
        ...prevState,
        otp: newOtp,  // Update the OTP in the state
        isOtpValid: false // Set OTP valid to false if any field is cleared
      }));
      inputRefs.current[index]?.focus();  // Stay on the same field
    }
  };

  // Handle backspace key press in OTP fields
  const handleKeyDown = (index, event) => {
    if (event.key === 'Backspace') {
      const newOtp = [...state.otp];
      newOtp[index] = '';  // Clear current digit only
      setState(prevState => ({
        ...prevState,
        otp: newOtp,  // Update the OTP in the state
      }));
      // Move focus to the previous input, but don't clear it
      if (index > 0 && state.otp[index] === '') {
        inputRefs.current[index - 1]?.focus();
      }
    }
  };

  useEffect(() => {
    let expiryTimer, resendTimer;
    const currentTimestamp = Date.now();
    const timeUntilExpiry = state.restaurantNumberOtpExpiry - currentTimestamp;
    const timeUntilResend = state.restaurantNumberOtpResendWindow - currentTimestamp;
    // Set OTP expiry timer
    if (timeUntilExpiry > 0) {
      expiryTimer = setTimeout(() => {
        setState(prevState => ({
          ...prevState,
          otpExpired: true
        }))
        if (state.maxResendAttempts > 0) {
          setState(prevState => ({
            ...prevState,
            showOtpExpiredMessage: true
          }))
        }
      }, timeUntilExpiry);
    } else {
      setState(prevState => ({
        ...prevState,
        otpExpired: true
      }))

      if (state.maxResendAttempts > 0) {
        setState(prevState => ({
          ...prevState,
          showOtpExpiredMessage: true
        }))
      }
    }
    // Hide the expired message after 5 seconds
    if (state.otpExpired && state.showOtpExpiredMessage) {
      const hideMessageTimer = setTimeout(() => {
        setState(prevState => ({
          ...prevState,
          showOtpExpiredMessage: false
        }))
      }, 5000);
      return () => clearTimeout(hideMessageTimer); // Clear timeout on component unmount
    }

    // Set resend button enable timer
    if (timeUntilResend > 0) {
      resendTimer = setTimeout(() =>
        setState(prevState => ({
          ...prevState,
          isResendEnabled: true
        })), timeUntilResend);
    } else {
      setState(prevState => ({
        ...prevState,
        isResendEnabled: true
      }))
    }

    return () => {
      clearTimeout(expiryTimer);
      clearTimeout(resendTimer);
    };
  }, [state.restaurantNumberOtpExpiry, state.restaurantNumberOtpResendWindow, state.otpExpired, state.maxResendAttempts, state.showOtpExpiredMessage]);

  // For sending otp
  const phone = `+91${state.restaurantPhoneNumber}`;
  localStorage.setItem("restaurantPhoneNumber", phone);

  // Send OTP (Initial)
  const handleVerifyButtonClick = async () => {
    const phone = `+91${state.restaurantPhoneNumber}`;
    console.log("Restaurant Phone Number", state.restaurantPhoneNumber);

    const mode = "send";
    try {
      const response = await sendOtpToOwner(phone, state.restaurantId, mode);
      const { otp, otpExpiry, otpResendWindow, uniqueAttemptId, totalAttempts } = response.otp;

      // Store OTP details in localStorage
      localStorage.setItem("restaurantNumberOtp", otp);
      localStorage.setItem("restaurantNumberOtpExpiry", Date.now() + otpExpiry * 1000);
      localStorage.setItem("restaurantNumberOtpResendWindow", Date.now() + otpResendWindow * 1000);
      localStorage.setItem("restaurantNumberUniqueAttemptId", uniqueAttemptId);
      localStorage.setItem("restaurantNumberOtpRetriesLeft", totalAttempts);

      // Update state with OTP details and show OTP modal
      setState((prevState) => ({
        ...prevState,
        otp: Array(6).fill(''), // Set OTP as an array of characters
        restaurantNumberOtpExpiry: Date.now() + otpExpiry * 1000,
        restaurantNumberOtpResendWindow: Date.now() + otpResendWindow * 1000,
        restaurantNumberUniqueAttemptId: uniqueAttemptId,
        maxResendAttempts: totalAttempts,
        showOtpModal: true,
        requestId: uniqueAttemptId, // Store request ID for future use
      }));
    } catch (error) {
      handleError(error);
    }
  };

  // Resend OTP
  const handleResendOtp = async () => {
    const requestId = localStorage.getItem("restaurantNumberUniqueAttemptId");
    if (!state.isResendEnabled || state.maxResendAttempts <= 0) return;

    try {
      const response = await reSendOtpToOwner(`+91${state.restaurantPhoneNumber}`, state.restaurantId, 'resend', requestId);
      const { otpExpiry, otpResendWindow } = response;

      const newOtpDetails = {
        otpExpiry: Date.now() + otpExpiry * 1000,
        otpResendWindow: Date.now() + otpResendWindow * 1000,
      };
      localStorage.setItem("restaurantNumberOtpDetails", JSON.stringify(newOtpDetails));
      // setOtpDetails(JSON.parse(localStorage.getItem("newOtpDetails")) || {});

      setState(prevState => ({
        ...prevState,
        otp: Array(6).fill(''),
        otpExpired: false,
        isResendEnabled: false,
        maxResendAttempts: prevState.maxResendAttempts - 1,
      }));
      inputRefs.current[0]?.focus();

      setTimeout(() => setState(prevState => ({
        ...prevState,
        isResendEnabled: true,
      })), otpResendWindow * 1000);

      // Decrement resend attempts and update local storage
      const newResendAttempts = state.maxResendAttempts - 2;
      setState((prevState) => ({
        ...prevState,
        maxResendAttempts: newResendAttempts
      }))
      localStorage.setItem('restaurantNumberOtpRetriesLeft', newResendAttempts);

      if (newResendAttempts > 0) {
        setState(prevState => ({
          ...prevState,
          isResendEnabled: false
        }))
      }

    } catch (error) {
      handleError(error);
    }
  };

  // Verify OTP
  const handleVerifyOtp = async () => {
    const finalOtp = state.otp.join("");
    const requestId = localStorage.getItem("restaurantNumberRequestId");
    if (state.inputsDisabled) return;

    if (state.otpExpired) {
      setState(prevState => ({
        ...prevState,
        errorMessage: "OTP expired. Please resend to get a new one."
      }))
      setTimeout(() => setState(prevState => ({
        ...prevState,
        errorMessage: ""
      })), 5000); // Clear error after 5 seconds
      return;
    }

    try {
      const response = await verifyOwnerOtp(`+91${state.restaurantPhoneNumber}`, finalOtp, requestId);
      if (response.message === "OTP verified successfully.") {
        setState((prevState) => ({
          ...prevState,
          showOtpModal: false,
          isOtpVerified: true,
          inputsDisabled: true,
        }));
      }
    } catch (error) {
      handleError(error);
    }
  };


  // Validate Form
  useEffect(() => {
    const isValid =
      state.restaurantName.trim() !== "" &&
      state.ownerName.trim() !== "" &&
      state.ownerEmail.trim() !== "" &&
      state.restaurantPhoneNumber.length === 10 &&
      state.area.trim() !== "" &&  // Area
      state.cityState.trim() !== "" &&  // City and State
      state.latitude !== "" &&  // Latitude
      state.longitude !== "" &&  // Longitude
      state.shopNo !== "" &&
      state.floor !== "" &&
      state.zipCode !== ""
    setState((prevState) => ({
      ...prevState,
      isFormValid: isValid,
    }));
  }, [state.restaurantName, state.ownerName, state.ownerEmail, state.restaurantPhoneNumber, state.area, state.cityState, state.latitude, state.longitude, state.shopNo, state.floor, state.zipCode]);

  // Redirect if not logged in
  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (!token) {
      navigate("/");
    }
  }, [navigate]);

  const restaurantInfoData = {
    restaurantName: state.restaurantName,
    ownerFullName: state.ownerName,
    restaurantPhoneNumber: state.restaurantPhoneNumber,
    ownerEmailId: state.ownerEmail,
    restaurantPrimaryContact: phone,
    latitude: state.latitude,
    longitude: state.longitude,
    shopNo: state.shopNo,
    floor: state.floor,
    area: state.area,
    cityState: state.cityState,
  };

  useEffect(() => {
    const savedMenuData = localStorage.getItem("menuOperationData");
    if (savedMenuData) {
      const parsedMenuData = JSON.parse(savedMenuData);
      localStorage.setItem("parsedMenuData", JSON.stringify(parsedMenuData))
    }
  }, []);

  useEffect(() => {
    const savedResDocsData = localStorage.getItem("restaurantDocumentsData");
    if (savedResDocsData) {
      const parsedData = JSON.parse(savedResDocsData);
      localStorage.setItem("parsedResDocsData", JSON.stringify(parsedData))
    }
  }, []);

  useEffect(() => {

    const fetchRestaurantInfo = async () => {
      try {
        const response = await readRestaurantInfo(state.restaurantId);

        if (response) {
          const {
            restaurantName,
            ownerFullName,
            restaurantPrimaryContact,
            ownerEmailId,
            latitude,
            longitude,
            shopNo,
            floor,
            area,
            // cityState,
          } = response;

          // Remove the '+91' prefix if it exists to display only the actual number
          const cleanPhoneNumber = restaurantPrimaryContact?.startsWith('+91')
            ? restaurantPrimaryContact.slice(3)
            : restaurantPrimaryContact;

          // Setting state with response values
          setState((prevState) => ({
            ...prevState,
            restaurantName: restaurantName || prevState.restaurantName,
            ownerName: ownerFullName || prevState.ownerName,  // Add a fallback
            restaurantPhoneNumber: cleanPhoneNumber || prevState.restaurantPhoneNumber, // Updated to only show the number
            ownerEmail: ownerEmailId || prevState.ownerEmail,
            latitude: latitude || prevState.latitude,
            longitude: longitude || prevState.longitude,
            shopNo: shopNo || prevState.shopNo,
            floor: floor || prevState.floor,
            area: area || prevState.area,
            // cityState: cityState || prevState.cityState,
            isPhoneFetched: !!cleanPhoneNumber, // Disable the phone field if fetched from API
            isOtpVerified: cleanPhoneNumber?.length === 10 // If phone number is valid (10 digits), mark OTP as verified
          }));
        }
      } catch (error) {
        console.error("Error fetching restaurant info:", error);
      }
    };

    // Only run on mount
    fetchRestaurantInfo();
  }, []);




  // Next Button function
  const handleNextClick = async () => {
    if (validateFields()) {
      try {
        localStorage.setItem("restaurantName", state.restaurantName)
        localStorage.setItem("ownerName", state.ownerName)
        const response = await updateRestaurantInfo(restaurantInfoData, state.restaurantId);
        localStorage.setItem("restaurantInfoData", JSON.stringify(restaurantInfoData));
        localStorage.setItem("restaurantInfoComplete", "true"); // A flag to indicate that the form has been saved
        setState(prevState => ({
          ...prevState,
          inputsDisabled: true,
        }));
        navigate("/menu-operational");
      } catch (error) {
        console.error("Error updating restaurant info:", error);
        throw error;
      }
    } else {
      alert("Please fill in all the required fields correctly.");
    }
  };

  const handleInputChange = (e, fieldName) => {
    const value = e.target.value;
    setState((prevState) => ({
      ...prevState,
      [fieldName]: value,
      [`${fieldName}Error`]: "", // Clear error on input change
    }));
  };


  const mapRef = useRef(null);
  const autocompleteRef = useRef(null);


  const [mapCenter, setMapCenter] = useState({ lat: 20.5937, lng: 78.9629 }); // Default to India

  useEffect(() => {
    // Fetch user location or fallback to IP-based location
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setMapCenter({ lat: latitude, lng: longitude });
        fetchAddress(latitude, longitude);
      },
      async () => {
        const { data } = await axios.get("https://ipapi.co/json/");
        const { latitude, longitude } = data;
        setMapCenter({ lat: latitude, lng: longitude });
        fetchAddress(latitude, longitude);
      }
    );
  }, []);

  const handleLocationSelect = () => {
    if (mapRef.current) {
      const center = mapRef.current.getCenter();
      const lat = center.lat();
      const lng = center.lng();

      setLocation((prev) => ({
        ...prev,
        latitude: lat,
        longitude: lng,
      }));
      fetchAddress(lat, lng);
      setState((prev) => ({ ...prev, showMapModal: false }));
    }
  };

  const handleShowMap = () => {
    setState((prev) => ({
      ...prev,
      showMapModal: true,
    }));
  };

  const fetchAddress = async (lat, lng) => {
    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
      );
      if (response.data.results.length > 0) {
        setLocation((prev) => ({ ...prev, address: response.data.results[0].formatted_address }));
      }
    } catch (error) {
      console.error("Error fetching address:", error);
    }
  };


  const [location, setLocation] = useState({
    latitude: "",
    longitude: "",
    address: "",
  });

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY, // Ensure API Key is set in .env file
    libraries: ["places"], // Load the Places library
  });

  const handlePlaceSelect = () => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace();
      if (place && place.geometry) {
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();
        setMapCenter({ lat, lng });
        setLocation({
          latitude: lat,
          longitude: lng,
          address: place.formatted_address,
        });
      }
    }
  };

  if (!isLoaded) return <div>Loading...</div>;






  // For saving Restaurant Info Data
  const handleSaveDraft = () => {

    // if (state.inputsDisabled) {
    //   return; // Don't allow saving if inputs are disabled
    // }

    // Stores the data in localStorage
    localStorage.setItem("restaurantInfoData", JSON.stringify(restaurantInfoData));

    // Display confirmation message
    setSaveMessage("Details Saved Successfully!");

    // Hide the message after 3 seconds
    setTimeout(() => setSaveMessage(""), 3000);
  };




  return (

    <div className="registration bg-gray-100 min-h-screen flex flex-col pt-5 overflow-auto">
      <main className="pt-20 pb-20 flex-1 w-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex-1 bg-gray-100 overflow-y-auto">
          <div className="flex flex-col">
            <h1 className="text-3xl font-bold mb-4">Restaurant information</h1>

            {/* Restaurant Name */}
            <div className="bg-white shadow-lg rounded-lg p-5 w-full">
              <h2 className="text-2xl font-bold">Restaurant name</h2>
              <p className="text-gray-600 font-medium text-sm mb-2">
                Customers will see this name on {APP_NAME}
              </p>
              <hr />
              <form className="mt-3">
                <input
                  type="text"
                  value={state.restaurantName}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    const regex = /^[a-zA-Z\s]*$/; // Allows only letters and spaces
                    if (regex.test(inputValue) && inputValue.length <= 40) {
                      handleInputChange(e, "restaurantName");
                      setState((prev) => ({ ...prev, restaurantNameError: "" }));
                    } else if (!regex.test(inputValue)) {
                      setState((prev) => ({
                        ...prev,
                        restaurantNameError: "Name can only contain letters and spaces",
                      }));
                    } else if (inputValue.length > 40) {
                      setState((prev) => ({
                        ...prev,
                        restaurantNameError: "Name cannot exceed 40 characters",
                      }));
                    }
                  }}
                  className={`mt-2 p-2 w-full rounded-lg border ${state.restaurantNameError ? "border-red-500" : "border-gray-300"
                    } outline-none shadow-sm sm:text-sm`}
                  placeholder="Restaurant name*"
                  disabled={state.inputsDisabled}
                />
                {state.restaurantNameError && (
                  <p className="text-red-500 text-sm mt-1">{state.restaurantNameError}</p>
                )}
              </form>
            </div>



            <div className="bg-white shadow-lg rounded-lg p-5 w-full mt-4">
              <h2 className="text-2xl font-bold">Owner details</h2>
              <p className="text-sm font-medium text-gray-600 mb-2">
                {APP_NAME} will use these details for all business communications and updates
              </p>
              <hr />
              <form className="mt-3">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                  {/* Owner Name Field */}
                  <div>
                    <input
                      type="text"
                      value={state.ownerName}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        const regex = /^[a-zA-Z\s]*$/; // Allow only letters and spaces
                        if (regex.test(inputValue) && inputValue.length <= 40) {
                          handleInputChange(e, "ownerName");
                          setState((prev) => ({ ...prev, ownerNameError: "" }));
                        } else if (!regex.test(inputValue)) {
                          setState((prev) => ({
                            ...prev,
                            ownerNameError: "Name can only contain letters and spaces",
                          }));
                        } else if (inputValue.length > 40) {
                          setState((prev) => ({
                            ...prev,
                            ownerNameError: "Name cannot exceed 40 characters",
                          }));
                        }
                      }}
                      className={`mt-2 p-2 w-full rounded-md border ${state.ownerNameError ? "border-red-500" : "border-gray-300"
                        } outline-none shadow-sm sm:text-sm`}
                      placeholder="Full name*"
                      disabled={state.inputsDisabled}
                    />
                    {state.ownerNameError && (
                      <p className="text-red-500 text-sm mt-1">{state.ownerNameError}</p>
                    )}
                  </div>
                  {/* Owner Email Field */}
                  <div>
                    <input
                      type="email"
                      value={state.ownerEmail}
                      onChange={(e) => handleInputChange(e, "ownerEmail")}
                      className="mt-2 p-2 w-full rounded-md border border-gray-300 outline-none shadow-sm sm:text-sm"
                      placeholder="Email Address*"
                      disabled={state.inputsDisabled}
                    />
                    {state.ownerEmailError && (
                      <p className="text-red-500 text-sm mt-1">{state.ownerEmailError}</p>
                    )}
                  </div>
                </div>

                <div className="mb-4">
                  <label className="text-lg font-medium">
                    Restaurant's  contact number
                  </label>
                  <p className="text-sm text-gray-500 mb-2">
                    Customers, delivery partners, and {APP_NAME} may call on this
                    number for order support </p>
                  <div className="relative flex items-center border border-gray-300 outline-none shadow-sm sm:text-sm rounded p-2">
                    <div className="flex items-center">
                      <img src="https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_India.png"
                        alt="India Flag" className="w-6 h-4 mr-2" />
                      <span className="text-gray-700">+91</span>
                    </div>
                    <input type="tel" placeholder="Phone number*" value={state.restaurantPhoneNumber}
                      onChange={handlePhoneNumberChange} minLength="10" maxLength="10"
                      className="ml-2 outline-none flex-1 border-l border-gray-300 pl-2" required disabled={state.isPhoneFetched || state.inputsDisabled} />
                    {state.restaurantPhoneNumber.length === 10 && (
                      <button
                        type="button"
                        className={`verify-btn absolute right-2  text-white ml-2 px-3 py-1 rounded ${state.isOtpVerified ? 'bg-green-500' : ''}text-sm`}
                        onClick={handleVerifyButtonClick} disabled={state.isOtpVerified}>
                        {state.isOtpVerified ? "Verified" : "Verify"}
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>




            <div className="bg-white shadow-lg rounded-lg px-5 pt-5 w-full mt-4">
              <h2 className="text-2xl font-bold">Restaurant address details</h2>
              <p className="text-sm font-medium text-gray-600 mb-6">
                Address details are based on the restaurant location mentioned above
              </p>
              <form onSubmit={(e) => e.preventDefault()}>

                {/* Latitude and Longitude
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div className="relative">
                    <input
                      type="text"
                      value={location.latitude}
                      readOnly
                      className="mt-2 p-2 w-full rounded-md border border-gray-300 outline-none shadow-sm sm:text-sm"
                      placeholder="Latitude"
                    />
                    <FaCrosshairs className="absolute right-3 mt-1 top-3 text-green-500 text-xl cursor-pointer" onClick={handleShowMap} />
                  </div>
                  <div className="relative">
                    <input
                      type="text"
                      value={location.longitude}
                      readOnly
                      className="mt-2 p-2 w-full rounded-md border border-gray-300 outline-none shadow-sm sm:text-sm"
                      placeholder="Longitude"
                    />
                  </div>
                </div> */}

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-20">
                  <div className="relative">
                    <input
                      type="text"
                      value={location.latitude}
                      readOnly
                      className="mt-2 p-2 w-full rounded-md border border-gray-300 outline-none shadow-sm sm:text-sm"
                      placeholder="Latitude"
                    />
                    <FaCrosshairs
                      className="absolute right-3 mt-1 top-3 text-green-500 text-xl cursor-pointer"
                      onClick={handleShowMap}
                    />
                  </div>
                  <div className="relative">
                    <input
                      type="text"
                      value={location.longitude}
                      readOnly
                      className="mt-2 p-2 w-full rounded-md border border-gray-300 outline-none shadow-sm sm:text-sm"
                      placeholder="Longitude"
                    />
                  </div>
                </div>

                {/* Shop No and Floor */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <input
                      type="text"
                      placeholder="Enter Shop no"
                      value={state.shopNo}
                      maxLength={50}
                      onChange={(e) => {
                        const value = e.target.value;
                        // Allow letters, numbers, and special characters, up to 50 characters
                        const regex = /^[a-zA-Z0-9\s!@#$%^&*(),.?":{}|<>-]{0,50}$/;
                        if (regex.test(value)) {
                          handleInputChange(e, "shopNo");
                        }
                      }}
                      className={`mt-2 p-2 w-full rounded-md border ${state.shopNoError ? 'border-red-500' : 'border-gray-300'
                        } outline-none shadow-sm sm:text-sm`}
                    />
                    {state.shopNoError && (
                      <p className="text-red-500 text-sm mt-1">{state.shopNoError}</p>
                    )}
                  </div>
                  <div>
                    <input
                      type="text"
                      placeholder="Enter Floor"
                      value={state.floor}
                      maxLength={50}
                      onChange={(e) => {
                        const value = e.target.value;
                        // Allow letters, numbers, and spaces only
                        const regex = /^[a-zA-Z0-9\s]*$/;
                        if (regex.test(value)) {
                          handleInputChange(e, "floor");
                        }
                      }}
                      className={`mt-2 p-2 w-full rounded-md border ${state.floorError ? 'border-red-500' : 'border-gray-300'
                        } outline-none shadow-sm sm:text-sm`}
                    />
                    {state.floorError && (
                      <p className="text-red-500 text-sm mt-1">{state.floorError}</p>
                    )}
                  </div>
                </div>
                {/* Area and City/State */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <input
                      type="text"
                      placeholder="Area / Sector / Locality*"
                      value={state.area}
                      maxLength={50}
                      onChange={(e) => {
                        const value = e.target.value;
                        // Allow letters, numbers, and spaces up to 50 characters
                        const regex = /^[a-zA-Z0-9\s]*$/;
                        if (regex.test(value)) {
                          handleInputChange(e, "area");
                        }
                      }
                      }
                      className={`mt-2 p-2 w-full rounded-md border ${state.areaError ? 'border-red-500' : 'border-gray-300'
                        } outline-none shadow-sm sm:text-sm`}
                    />
                    {state.areaError && (
                      <p className="text-red-500 text-sm mt-1">{state.areaError}</p>
                    )}
                  </div>


                  <div>
                    <input
                      type="text"
                      placeholder="City/State"
                      value={state.cityState}
                      maxLength={50}
                      onChange={(e) => {
                        const value = e.target.value;
                        // Allow letters, numbers, and spaces up to 50 characters
                        const regex = /^[a-zA-Z0-9\s]*$/;
                        if (regex.test(value)) {
                          handleInputChange(e, "cityState");
                        }
                      }}
                      className={`mt-2 p-2 w-full rounded-md border ${state.cityStateError ? 'border-red-500' : 'border-gray-300'
                        } outline-none shadow-sm sm:text-sm`}
                    />
                    {state.cityStateError && (
                      <p className="text-red-500 text-sm mt-1">{state.cityStateError}</p>
                    )}
                  </div>



                </div>


                {/* Landmark and Zip Code */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <input
                    type="text"
                    placeholder="Add any nearby landmark (optional)"
                    value={state.landmark}
                    maxLength={50}
                    onChange={(e) => {
                      const value = e.target.value;
                      const regex = /^[a-zA-Z0-9\s]*$/; // Only letters, numbers, spaces
                      if (regex.test(value)) {
                        handleInputChange(e, "landmark");
                      }
                    }}
                    className="mt-2 p-2 w-full rounded-md border border-gray-300 outline-none shadow-sm sm:text-sm"
                  />
                  <input
                    type="text"
                    placeholder="ZipCode"
                    value={state.zipCode}
                    maxLength={6}
                    onChange={(e) => handleInputChange(e, "zipCode")}
                    className={`mt-2 p-2 w-full rounded-md border ${state.zipCodeError ? 'border-red-500' : 'border-gray-300'
                      } outline-none shadow-sm sm:text-sm`}
                  />
                  {state.zipCodeError && (
                    <p className="text-red-500 text-sm mt-1">{state.zipCodeError}</p>
                  )}
                </div>
                {/* Address Note */}

                <div>
                  <p className="address-note text-sm px-2 py-2 rounded-md mt-4">
                    Please ensure that this address is the same as mentioned on your FSSAI license
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>

      <footer className="fixed bottom-0 w-2/3">
        {/* Save Button */}
        <div className="flex justify-center md:justify-end sm:justify-end p-4 space-x-4">

          {/* <button
            className={`bg-slate-500 text-white py-2 px-10 rounded w-full md:w-auto h-12 flex justify-center items-center whitespace-nowrap ${state.isFormValid ? "cursor-pointer opacity-100" : "cursor-not-allowed opacity-50"}`}
            onClick={handleSaveDraft}
            disabled={!state.isFormValid} // Button is disabled if the form is invalid
          >
            Save As Draft
          </button> */}


          <div className="flex flex-col items-center md:items-start">
            <button
              className="bg-slate-500 text-white py-2 px-10 rounded w-full md:w-auto h-12 flex justify-center items-center whitespace-nowrap cursor-pointer opacity-100"

              onClick={handleSaveDraft}
            // disabled={!state.isFormValid} // Button is disabled if the form is invalid
            >
              Save As Draft
            </button>

            {/* Display Save Confirmation Message */}
            {saveMessage && (
              <p className="text-green-600 mt-2 text-center md:text-left">
                {saveMessage}
              </p>
            )}
          </div>

          {/* Next Button */}
          <button
            className={`bg-green-600 text-white py-2 px-10 rounded w-full md:w-auto h-12 flex justify-center items-center ${state.isFormValid ? "cursor-pointer" : "cursor-not-allowed opacity-50"}`}
            onClick={handleNextClick}
            disabled={!state.isFormValid} // Prevent going to the next page if the form is invalid
          >
            Next
          </button>

        </div>
      </footer>



      {/* Rendering Required registration details page */}
      {state.showModal && <RequiredRegistrationDetails toggleModal={toggleModal} />}
      {state.showReferModal && (
        <ReferrerModal
          toggleReferModal={toggleReferModal}
          onClickSuccess={() => {
            toggleReferModal();
          }}
        />
      )}

      {state.showOtpModal && (
        // Otp verification section
        <div className="fixed inset-0 flex items-center justify-center bg-gray-950 bg-opacity-75 z-50">
          <div className="bg-white rounded-lg shadow-lg w-96 p-6">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-semibold">OTP Verification</h2>
              <button
                onClick={() =>
                  setState((prevState) => ({
                    ...prevState,
                    showOtpModal: false,
                  }))
                }
                className="text-gray-500 hover:text-gray-700"
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
            <div className="text-center">
              <p className="text-gray-500">Check text messages for your OTP</p>
              <div className="flex justify-center mt-4 space-x-2">
                {state.otp && state.otp.length > 0 && state.otp.map((digit, index) => (
                  <input key={index} type="text" maxLength="1"
                    className="w-10 h-10 border rounded text-center shadow-md focus:outline-none focus:ring-2 focus:ring-red-500"
                    value={digit} onChange={(e) => handleOtpChange(index, e.target.value)}
                    onKeyDown={(e) => handleKeyDown(index, e)}
                    ref={(el) => inputRefs.current[index] = el} disabled={state.isOtpVerified || state.inputsDisabled}
                  />
                ))}
              </div>
              {state.errorMessage && <p className="text-red-500 font-bold mt-2">{state.errorMessage}</p>}
              <div className="mt-2">
                <button
                  className={`bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded ${state.otp.join('').length === 6 ? '' : 'opacity-50 cursor-not-allowed'}`}
                  onClick={handleVerifyOtp}
                  disabled={state.otp.join('').length !== 6}
                >
                  Verify OTP
                </button>
              </div>
              {/* Don't delete this */}
              {/* Error Message Display */}
              {/* {state.otpError && (
                <p className="text-red-500 mt-2">{state.otpError}</p> // Error message in red
              )} */}
              <div className="mt-2 text-gray-500">
                Not received OTP?{' '}
                <button
                  className={`text-red-500 font-bold hover:underline ${state.isResendEnabled && state.maxResendAttempts > 0 ? '' : 'opacity-50 cursor-not-allowed'}`}
                  onClick={handleResendOtp}
                  disabled={!state.isResendEnabled || state.maxResendAttempts <= 0}
                >
                  Resend Now
                </button>
                {state.maxResendAttempts <= 0 && (
                  <p className="text-red-500 text-sm font-bold mt-2">Maximum resend attempts reached.</p>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {
        state.showLogoutModal && (
          <LogoutModel
            onClickLogout={confirmLogout}
            onClickCancel={() =>
              setState((prevState) => ({
                ...prevState,
                showLogoutModal: false,
              }))
            }
          />
        )
      }



      {/* {state.showMapModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-950 bg-opacity-75 z-50">
          <div className="bg-white rounded-lg shadow-lg w-4/5 p-6 relative">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-semibold">Choose Location</h2>
              <div className="flex items-center justify-end gap-4">
                <button
                  className="bg-green-600 text-white px-4 py-2 rounded shadow-md"
                  onClick={() => setState((prev) => ({ ...prev, showMapModal: false, }))}>
                  Done
                </button>
                <button
                  className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded shadow-md"
                  onClick={() => setState((prev) => ({ ...prev, showMapModal: false, }))}
                >
                  Close
                </button>
              </div>
            </div>
            <LocationPicker onLocationSelect={handleLocationSelect} />
          </div>
        </div>
      )} */}

      {/* Map Modal */}
      {state.showMapModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-950 bg-opacity-75 z-50">
          <div className="bg-white rounded-lg shadow-lg w-4/5 h-4/5 p-6 relative">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-semibold">Choose Location</h2>
              <div className="flex items-center justify-end gap-4">
                <button
                  className="bg-green-600 text-white px-4 py-2 rounded shadow-md"
                  onClick={handleLocationSelect}
                >
                  Done
                </button>
                <button
                  className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded shadow-md"
                  onClick={() => setState((prev) => ({ ...prev, showMapModal: false }))}
                >
                  Close
                </button>
              </div>
            </div>
            <div className="w-full h-96 relative">

              <Autocomplete
                onLoad={(auto) => (autocompleteRef.current = auto)}
                onPlaceChanged={handlePlaceSelect}
              >
                <input
                  type="text"
                  className="p-2 border rounded-md w-full mb-4"
                  placeholder="Search location"
                />
              </Autocomplete>


              <GoogleMap
                mapContainerStyle={{ width: "100%", height: "100%" }}
                center={mapCenter}
                zoom={14}
                onLoad={(map) => (mapRef.current = map)}
                onDragEnd={() => {
                  if (mapRef.current) {
                    const center = mapRef.current.getCenter();
                    const lat = center.lat();
                    const lng = center.lng();
                    setMapCenter({ lat, lng });
                    fetchAddress(lat, lng);
                  }
                }}
                options={{
                  streetViewControl: false,
                  mapTypeControl: false,
                }}
              />

              {/* Fixed marker at center */}
              <div
                style={{
                  position: "absolute",
                  top: "59.5%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  zIndex: 1,
                  pointerEvents: "none",
                }}
              >
                <img
                  src="https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi2.png"
                  alt="marker"
                />
              </div>

              {/* Address Display */}
              <div className="mt-4 p-2 border rounded-md bg-gray-100">
                <strong>Selected Location:</strong> {location.address || "No location selected"}
              </div>
            </div>
          </div>
        </div>
      )}


    </div >
  );
};
export default Registration;





