import React, { useEffect, useState } from "react";
import { faUser, faEdit, faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReferrerModal from "./ReferrerModal";
import { fetchUserReferralsForOnboarding, deleteUserReferralsForOnboarding } from "../services/Api";

const ReferrerCard = ({ handleYes }) => {
  const [state, setState] = useState({
    showMessage: false,
    mobileNumber: localStorage.getItem("referrerMobileNumber") || "",
    restaurantId: localStorage.getItem("restaurant_Id") || "",
    showModal: false,
  });

  useEffect(() => {
    const fetchReferrals = async () => {
      try {
        const referrals = await fetchUserReferralsForOnboarding(state.restaurantId);
        const fetchedMobileNumber = referrals.referrerMobileNumber || "";
        
        // Set state based on whether a referral mobile number was found
        setState((prevState) => ({
          ...prevState,
          mobileNumber: fetchedMobileNumber,
          showMessage: !fetchedMobileNumber, // Show default message only if no mobile number is found
        }));

        // Store mobile number in localStorage for persistence on refresh
        if (fetchedMobileNumber) {
          localStorage.setItem("referrerMobileNumber", fetchedMobileNumber);
        } else {
          localStorage.removeItem("referrerMobileNumber");
        }
      } catch (error) {
        console.error("Error fetching referrals:", error);
      }
    };
    fetchReferrals();
  }, [state.restaurantId]);

  const toggleModal = () => setState((prevState) => ({ ...prevState, showModal: !prevState.showModal }));

  // Callback to handle successful referral addition
  const handleReferralSuccess = (mobileNumber) => {
    setState((prevState) => ({
      ...prevState,
      mobileNumber,
      showModal: false, // Close the modal after updating
      showMessage: false, // Hide default message when referral added
    }));
    localStorage.setItem("referrerMobileNumber", mobileNumber);
  };

  const handleDelete = async () => {
    try {
      await deleteUserReferralsForOnboarding(state.restaurantId, `+91${state.mobileNumber}`);
      setState((prevState) => ({
        ...prevState,
        mobileNumber: "",
        showMessage: true, // Show default message after deletion
      }));
      localStorage.removeItem("referrerMobileNumber");
    } catch (error) {
      console.error("Error deleting referral:", error);
    }
  };

  return (
    <div>
      {state.mobileNumber ? (
        <ThankYouMessage mobileNumber={state.mobileNumber} toggleModal={toggleModal} onDelete={handleDelete} />
      ) : (
        <NoReferrerMessage
          showMessage={state.showMessage}
          handleYes={handleYes}
          onAddReferral={toggleModal} // Pass function to open modal for adding referral
        />
      )}
      {state.showModal && (
        <ReferrerModal onSuccess={handleReferralSuccess} toggleReferModal={toggleModal} initialMobileNumber={state.mobileNumber} />
      )}
    </div>
  );
};

const ThankYouMessage = ({ mobileNumber, toggleModal, onDelete }) => (
  <div className="thank-you-message flex items-center justify-center gap-10 text-center mt-4">
    <div>
      <h3 className="text-l font-bold text-green-600">You're referred by {mobileNumber}</h3>
      <p className="text-sm text-gray-800 mt-2">Thank you for providing the referrer details!</p>
    </div>
    <div className="flex gap-2">
      <button onClick={toggleModal} className="text-blue-500 text-sm font-semibold underline">
        <FontAwesomeIcon icon={faEdit} className="mr-1 text-green-600" />
      </button>
      <button onClick={onDelete} className="text-red-500 text-sm font-semibold underline">
        <FontAwesomeIcon icon={faTrash} className="mr-1 text-red-600" />
      </button>
    </div>
  </div>
);

const NoReferrerMessage = ({ showMessage, onAddReferral }) => (
  <>
    {showMessage && (
      <div className="refer flex justify-between items-center border p-3 rounded-lg mt-6">
        <div className="flex gap-2">
          <FontAwesomeIcon icon={faUser} className="icons text-xl" />
          <p className="text-black font-semibold">Do you have a referral to add?</p>
        </div>
        <div className="flex gap-2">
          <div onClick={onAddReferral} className="yes font-semibold px-3 py-1 border rounded-xl cursor-pointer text-xs"
            style={{ borderColor: "#2ca721" }}
          >
            Add Referral
          </div>
        </div>
      </div>
    )}
  </>
);

export default ReferrerCard;